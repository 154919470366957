import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
//Components
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import Grow from '../../library/Grow';
import { ArrowBack, ContactMail, Delete } from '@material-ui/icons';
import {
  Box,
  Breadcrumbs,
  Container,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  useTheme
} from '@material-ui/core';
import UserSelector from '../Property/UserSelector';
import PaperBody from '../../library/CustomPaper/PaperBody';
import userReset from '../../reset/userReset';
import {
  fetchTicket,
  saveUpdateTicket,
  updateTicket
} from '../../actions/TicketActions';
import { fetchPropertyTypesIfNeeded } from '../../actions/PropertyTypeActions';
import Grid from '@material-ui/core/Grid';

import TicketActitivitiesList from '../TicketActivity/TicketActitivitiesList';
import AddTicketActivity from '../TicketActivity/AddTicketActivity';
import { fetchTicketStatuses } from '../../actions/TicketStatusActions';
import TicketPropertyList from '../TicketProperty/TicketPropertyList';
import SwipeableViews from 'react-swipeable-views';
import SuggestedTicketPropertyList from '../TicketProperty/SuggestedTicketPropertyList';
import { fetchAttributesGroupsIfNeeded } from '../../actions/AttributeGroupActions';
import { fetchOperationTypes } from '../../actions/OperationTypeActions';
import { fetchCurrencies } from '../../actions/CurrencyActions';
import { fetchCountries } from '../../actions/CountryActions';
import { updateProperty } from '../../actions/PropertyActions';
import UserPresentationCard from '../Home/cards/UserPresentationCard';
import TicketStatusBar from '../TicketStatus/TicketStatusBar';
import { createUser } from '../../actions/UserActions';
import AddPropertyClosedOperation from '../PropertyClosedOperation/AddPropertyClosedOperation';
import { useHistory } from 'react-router';
import Interests from './Interests';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    userSelect: 'none',
    marginTop: '12px',
    height: '100%'
  }
}));

const tabs = ['timeline', 'properties'];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function CRUDTicket(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { idTicket } = useParams();
  const theme = useTheme();
  const history = useHistory();
  //Store
  const tickets = useSelector(state => state.tickets);
  const ticketProperties = useSelector(state => state.ticketProperties);
  const ticketActivities = useSelector(state => state.ticketActivities);
  const properties = useSelector(state => state.properties);
  const ticket = idTicket ? tickets.update.activo : null;
  const users = useSelector(state => state.users);
  const user =
    ticket && ticket.user_id ? users.byId.users[ticket.user_id] : null;

  const disabled =
    tickets.update.isUpdating ||
    tickets.create.isCreating ||
    tickets.byId.isFetching;

  //State
  const [valueInt, setValueInt] = useState(0);
  const [value, setValue] = useState('timeline');

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    if (idTicket) dispatch(fetchTicket(idTicket));
    dispatch(
      fetchPropertyTypesIfNeeded({
        with: ['childrenType'],
        hierarchy: 0,
        used: '1'
      })
    );
    dispatch(fetchTicketStatuses({}));
    dispatch(
      fetchAttributesGroupsIfNeeded({ with: ['attribute.attributeValue'] })
    );
    dispatch(fetchOperationTypes({}));
    dispatch(fetchCurrencies());
    dispatch(
      fetchCountries({ with: ['state.location.location'], limit: '-1' })
    );

    return function cleanup() {
      //TODO
      // ticketReset.resetAll(dispatch());
      userReset.resetAll(dispatch);
    };
  }, []);

  useEffect(() => {
    if (ticket.id) {
      let cambio = {};
      ticket?.ticket_property?.forEach((idTicketProperty, i) => {
        let ticketProperty =
          ticketProperties.byId.ticketProperties[idTicketProperty];
        const property = ticketProperty?.property_id
          ? properties.byId.properties[ticketProperty.property_id]
          : null;
        cambio[ticketProperty?.property_id] = property;
      });
      ticket?.ticket_activity?.forEach((idTicketActivity, i) => {
        let ticketActivity =
          ticketActivities.byId.ticketActivities[idTicketActivity];
        const property = ticketActivity?.property_id
          ? properties.byId.properties[ticketActivity.property_id]
          : null;
        cambio[ticketActivity?.property_id] = property;
      });
      dispatch(updateProperty(cambio));
    }
  }, [ticket?.ticket_property]);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setValueInt(tabs.indexOf(newValue));
  };

  const handleChangeIndexTab = index => {
    setValue(tabs[index]);
    setValueInt(index);
  };

  const handleChangeTicketStatus = id => {
    let cambio = { ticketStatus_id: id };
    changeTicket(cambio);
  };

  const handleChangeTicketConsultant = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTicket(cambio);
  };

  const changeTicket = cambio => {
    if (idTicket) {
      dispatch(updateTicket(cambio));
      dispatch(saveUpdateTicket());
    } else {
      dispatch(createUser(cambio));
    }
  };

  return (
    <Container
      maxWidth={'lg'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <>
        <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/tickets">
            Oportunidades
          </Link>
        </Breadcrumbs>
        <Typography component={'h1'} variant={'h1'}>
          Gestionar oportunidad
        </Typography>
      </>
      <CustomPaper>
        <PaperHeader>
          <Tooltip title={'Volver'}>
            <IconButton
              onClick={() => {
                history.push('/tickets');
              }}
            >
              <ArrowBack />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Eliminar'}>
            <IconButton>
              <Delete />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Editar datos de contacto'}>
            <IconButton>
              <ContactMail />
            </IconButton>
          </Tooltip>
          <Grow />
          <AddPropertyClosedOperation
            disabled={ticket.status === 0 || disabled}
          />
        </PaperHeader>
      </CustomPaper>
      <CustomPaper>
        <PaperHeader>
          <UserPresentationCard user={user}>
            <Interests ticket={ticket} />
          </UserPresentationCard>
        </PaperHeader>
        <PaperBody>
          <Box
            width={'100%'}
            marginBottom={'20px'}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <TicketStatusBar
              ticketStatus_id={ticket.ticketStatus_id}
              handleChangeTicketStatus={handleChangeTicketStatus}
              disabled={disabled}
            />
            <UserSelector
              model={ticket}
              id={'consultant_id'}
              description={'Asesor asignado'}
              handleChangeModel={handleChangeTicketConsultant}
              disabled={disabled}
            />
          </Box>
          <TicketPropertyList ticket={ticket} />
          <Grid item md={12} sm={12} xs={12}>
            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                centered
                disabled={disabled}
              >
                <Tab label="Línea de tiempo" value={'timeline'} />
                <Tab label="Propiedades" value={'properties'} />
              </Tabs>
            </Box>
          </Grid>
        </PaperBody>
      </CustomPaper>
      <Grid item md={12} sm={12} xs={12}>
        <SwipeableViews
          axis={'x'}
          index={valueInt}
          onChangeIndex={handleChangeIndexTab}
        >
          <TabPanel value={valueInt} index={0}>
            <CustomPaper>
              <PaperHeader>
                <AddTicketActivity user={user} disabled={disabled} />
              </PaperHeader>
              <PaperBody>
                <TicketActitivitiesList ticket={ticket} />
              </PaperBody>
            </CustomPaper>
          </TabPanel>
          <TabPanel value={valueInt} index={1}>
            <SuggestedTicketPropertyList />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Container>
  );
}

CRUDTicket.propTypes = {};

export default CRUDTicket;
