import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import House from '../../../assets/img/icon/house.png';
import Shelter from '../../../assets/img/icon/megaphone.png';
import Question from '../../../assets/img/icon/choice.png';
import Cheers from '../../../assets/img/icon/cheers.png';
import No from '../../../assets/img/icon/prohibited.png';
import Man from '../../../assets/img/icon/group.png';
import Box from '@material-ui/core/Box';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    '& h7': {
      color: 'rgba(70,69,69,0.87)',
      fontSize: '14px'
    },
    '& span': {
      color: 'rgba(70,69,69,0.87)',
      fontSize: '10px'
    },
    '& img': {
      padding: '0px',
      width: '85%',
      objectFit: 'cover',
      height: '80px'
    }
  },
  trendingUp: {
    color: theme.palette.success.main
  },
  trendingDown: {
    color: theme.palette.error.main
  },
  image: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

const externalSystemsIcons = {
  House: House,
  Man: Man,
  Shelter: Shelter,
  Question: Question,
  Cheers: Cheers,
  No: No
};

function SimpleAnalyticCard(props) {
  const classes = useStyles();

  return (
    <>
      <Grid item md={2} sm={12} xs={12}>
        <CustomPaper style={{ height: '100%', marginTop: '0' }}>
          <PaperBody style={{ padding: '0px' }}>
            <Box className={classes.card}>
              {props.icon && (
                <Box className={classes.image}>
                  <img src={externalSystemsIcons[props.icon]} />
                </Box>
              )}
              <Box style={{ padding: '16px 12px' }}>
                <Box
                  display={'flex'}
                  gridColumnGap={'10px'}
                  justifyContent={'flex-start'}
                  alignItems={'center'}
                >
                  <Typography variant={'h7'} component={'h7'}>
                    {props.title}
                  </Typography>
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Typography variant={'h1'}>{props.value}</Typography>
                  {!!props.percentage && (
                    <Box
                      display={'flex'}
                      justifyContent={'flex-end'}
                      alignItems={'center'}
                      flexDirection={'column'}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        className={
                          props.percentage > 0
                            ? classes.trendingUp
                            : classes.trendingDown
                        }
                      >
                        {props.percentage > 0 ? (
                          <TrendingUpIcon />
                        ) : (
                          <TrendingDownIcon />
                        )}
                        <Typography variant={'h6'} component={'h6'}>
                          {`${
                            !Number.isNaN(parseInt(props.percentage))
                              ? parseInt(props.percentage)
                              : '100'
                          }%`}
                        </Typography>
                      </Box>
                      <Typography variant={'caption'} component={'span'}>
                        vs período anterior
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </PaperBody>
        </CustomPaper>
      </Grid>
    </>
  );
}

export default SimpleAnalyticCard;
