import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
//Components
import {
  IconButton,
  InputAdornment,
  MenuItem,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';

import Box from '@material-ui/core/Box';
import { Skeleton } from '@material-ui/lab';
import Button from '@material-ui/core/Button';

//Img
import CropFreeIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
//reset
//Actions
import {
  createAsset,
  deleteAsset,
  updateAsset
} from '../../actions/AssetActions';
import clone from 'lodash/clone';
import union from 'lodash/union';

import c from '../../constants/Constants';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import PaperFooter from '../../library/CustomPaper/PaperFooter';
import ModalTypeAsset from './ModalTypeAsset';
import ModalURLAsset from './ModalURLAsset';

let moment = require('moment');

const useStyles = makeStyles(theme => ({
  inputFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    top: '50%',
    left: '50%',
    position: 'absolute',
    zIndex: '-1'
  },
  btn: {
    height: '56px'
  },
  preview: {
    width: '60px'
  },
  filename: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '180px',
    overflow: 'hidden'
  }
}));

export default function EmbeddedAssetsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { property, files, setFiles, changeProperty } = props;
  let inputfile = React.createRef();

  //Store
  const assets = useSelector(state => state.assets);

  //State
  const [search, setSearch] = useState('');
  const [searchFetch, setSearchFetch] = useState('');
  const [loading, setLoading] = React.useState(false);
  const [openModalTypeAsset, setOpenModalTypeAsset] = React.useState(false);
  const [openModalURL, setOpenModalURL] = React.useState(false);
  const [url, setUrl] = React.useState('');

  const disabled = assets.byId.isFetching || assets.delete.isDeleting;

  //Hooks
  const timer = useRef();

  //Effects

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), 500);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    if ((!searchPhase && !search) || searchPhase === '') {
      setSearchFetch('');
    } else {
      setSearchFetch(searchPhase ? searchPhase : searchFetch);
    }
  };

  const handleOpenModalType = () => {
    setOpenModalTypeAsset(true);
  };

  const handleClickOpen = type => {
    if (type === 'file') inputfile.current.click();
    else setOpenModalURL(true);
    setOpenModalTypeAsset(false);
    cleanSearch();
  };
  const cleanSearch = () => {
    setSearchFetch('');
    setSearch('');
  };

  const handleAddAssets = (e, type) => {
    e.preventDefault();
    let cambio = {};
    let id = Date.now() + Math.random() + '-';
    if (type === 'file') {
      //Preview
      let fotos = [];
      setLoading(true);
      for (let i = 0; i < inputfile.current.files.length; i++) {
        let filename = inputfile.current.files[i].name.replace(
          /[^a-zA-Z0-9]/g,
          '_'
        );
        Object.defineProperty(inputfile.current.files[i], 'name', {
          writable: true,
          value: filename
        });
        fotos.push(inputfile.current.files[i]);
      }
      let fotosClone = clone(files);
      setFiles(union(fotosClone, fotos));

      //Cargar foto
      for (let i = 0; i < inputfile.current.files.length; i++) {
        let fileSubido = inputfile.current.files[i];

        let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
        let reader = new FileReader();
        reader.onloadend = e => {
          setLoading(false);
        };

        if (fileSubido) {
          reader.readAsDataURL(fileSubido);
        }
        cambio.originalFilename = filename;
        cambio.filename = filename;
        cambio.mimeType = fileSubido.type;
      }
    } else {
      cambio.url = url;
      setUrl('');
    }
    cambio.typeAsset = type;
    cambio.idProperty = property.id;
    changeAsset(cambio, id);
  };

  const handleChangeAsset = (e, id) => {
    //Verificar si ya esta siendo actualizado
    let asset =
      id &&
      assets.update.activos.indexOf(id) === -1 &&
      assets.create.nuevos.indexOf(id) === -1
        ? assets.byId.assets[id]
        : null;
    if (asset) {
      let actualizar = asset ? clone(asset) : null;
      if (actualizar) {
        let cambioAsset = {};
        cambioAsset[actualizar.id] = actualizar;
        if (id.toString().indexOf('-') === -1)
          dispatch(updateAsset(cambioAsset));
        else dispatch(createAsset(cambioAsset));
      }
    }
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeAsset(cambio, id);
  };

  const changeAsset = (cambio, id) => {
    cambio.id = id;
    let cambioAsset = {};
    cambioAsset[id] = cambio;
    if (id.toString().indexOf('-') === -1) dispatch(updateAsset(cambioAsset));
    else dispatch(createAsset(cambioAsset));
  };

  const handleDeleteAsset = id => {
    let cambio = assets.create.nuevo[id]
      ? clone(assets.create.nuevo[id])
      : { id: id };
    let cambioAsset = {};
    cambioAsset[id] = cambio;
    dispatch(deleteAsset(cambioAsset));
  };

  const handleChangeEstadoAsset = asset => {
    let cambio = clone(asset);
    cambio.status = !asset.status;
    changeAsset(cambio, asset.id);
  };

  const previewFileAsset = asset => {
    if (asset.typeAsset === 'url') window.open(asset.url);
    else window.open(c.API_HOST + asset.path);
    // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + c.API_HOST + asset.path + "'></iframe>")
  };

  // const downloadFileAsset = (asset) => {
  //     var link = document.createElement("a");
  //     link.download = asset.originalFilename;
  //     link.href =  'attachment;' + asset.mimeType + ',' + encodeURI(c.API_HOST + asset.path);
  //     // link.target = "_blank";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // };

  let Assets = [];

  if (assets.byId.isFetching) {
    Assets = [...Array(5).keys()].map(i => {
      return (
        <TableRow key={'inv' + i}>
          {[...Array(10).keys()].map(i => (
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  if (property && property.asset)
    Assets = property.asset.map(idAsset => {
      let asset = assets.byId.assets[idAsset];
      if (assets.update.activos.indexOf(idAsset) !== -1)
        asset = assets.update.activo[idAsset];
      if (assets.create.nuevos.indexOf(idAsset) !== -1)
        asset = assets.create.nuevo[idAsset];

      if (
        asset &&
        asset.type !== 'Photo' &&
        asset.type !== 'Video' &&
        asset.type !== 'Tour' &&
        asset.type !== 'Authorization' &&
        (searchFetch === '' ||
          asset.nameAsset.indexOf(searchFetch) !== -1 ||
          asset.originalFilename.indexOf(searchFetch) !== -1)
      )
        return (
          <TableRow key={asset.id}>
            <TableCell>
              {asset &&
                asset.mimeType &&
                asset.path &&
                asset.mimeType.indexOf('image') !== -1 && (
                  <img
                    className={classes.preview}
                    src={c.API_HOST + asset.path}
                  />
                )}
            </TableCell>
            <TableCell>
              {asset && asset.id.toString().indexOf('-') === -1 ? asset.id : ''}
            </TableCell>
            <TableCell className={classes.filename}>
              {asset && asset.typeAsset === 'url' ? (
                <TextField
                  id="url"
                  placeholder="URL"
                  variant="outlined"
                  label={'URL'}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  // disabled={disabled}
                  fullWidth
                  margin={'dense'}
                  value={asset ? asset.url : ''}
                  onChange={e => handleChangeAsset(e, asset.id)}
                  // onKeyDown={(e) => handleKeyDown(e)}
                />
              ) : (
                asset.originalFilename
              )}
            </TableCell>
            <TableCell>
              <TextField
                id="nameAsset"
                placeholder="Descripción"
                variant="outlined"
                label={'Descripción'}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  type: 'text'
                }}
                fullWidth
                margin={'dense'}
                value={asset ? asset.nameAsset : ''}
                onChange={e => handleChangeAsset(e, asset.id)}
              />
            </TableCell>
            <TableCell>
              <TextField
                key="type"
                id={'type'}
                select
                label="Tipo"
                fullWidth
                value={asset && asset.type ? asset.type : ''}
                onChange={e => handleChangeAsset(e, asset.id)}
                InputProps={{
                  id: 'type',
                  name: 'type'
                }}
                margin={'dense'}
                variant="outlined"
              >
                <MenuItem value={'Tax'}>Impuesto</MenuItem>
                <MenuItem value={'Contract'}>Contrato</MenuItem>
                <MenuItem value={'Document'}>Documento</MenuItem>
                <MenuItem value={'Authorization'}>
                  Autorización del propietario
                </MenuItem>
                <MenuItem value={'Folder'}>Carpeta de archivos</MenuItem>
                <MenuItem value={'Otro'}>Otro</MenuItem>
              </TextField>
            </TableCell>
            <TableCell>
              {asset && asset.created_at
                ? moment(asset.created_at).format('DD-MM-YYYY')
                : moment().format('DD-MM-YYYY')}
            </TableCell>
            <TableCell>
              {asset && asset.id.toString().indexOf('-') === -1 && (
                <Switch
                  disabled={disabled}
                  checked={!!asset.status}
                  onChange={() => handleChangeEstadoAsset(asset)}
                  name={'status'}
                  color={'primary'}
                  size={'small'}
                />
              )}
            </TableCell>
            <TableCell>
              <Box
                display={'flex'}
                flexDirection={'row'}
                width={'130px'}
                alignItems={'center'}
              >
                {/*<Tooltip title="Descargar">*/}
                {/*    <IconButton edge="end"*/}
                {/*                aria-label="descargar"*/}
                {/*                onClick={() => downloadFileAsset(asset)}*/}
                {/*    >*/}
                {/*        <CloudDownloadIcon/>*/}
                {/*    </IconButton>*/}
                {/*</Tooltip>*/}
                <Tooltip title="Ver">
                  <IconButton
                    edge="end"
                    aria-label="visualizar"
                    onClick={() => previewFileAsset(asset)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                {asset && asset.id.toString().indexOf('-') !== -1 && (
                  <Tooltip title="Eliminar">
                    <IconButton
                      aria-label="editar"
                      edge="end"
                      disabled={disabled}
                      onClick={() => handleDeleteAsset(idAsset)}
                    >
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                )}
                {asset &&
                  asset.mainImage === 1 &&
                  ((asset &&
                    asset.mimeType &&
                    asset.mimeType.indexOf('image') !== -1) ||
                    !asset) && (
                    <Tooltip title="Foto principal">
                      <IconButton
                        aria-label="editar"
                        edge="end"
                        disabled={disabled}
                      >
                        <FavoriteIcon color="secondary" />
                      </IconButton>
                    </Tooltip>
                  )}
              </Box>
            </TableCell>
          </TableRow>
        );
    });

  if (Assets.length === 0)
    Assets.push(
      <TableRow key={0}>
        <TableCell align="center" colSpan={11}>
          No se encontraron contenidos
        </TableCell>
      </TableRow>
    );

  return (
    <Box>
      <CustomPaper>
        <PaperHeader>
          <Typography variant={'overline'} component={'span'}>
            Archivos
          </Typography>
        </PaperHeader>
        <PaperHeader>
          <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
            <TextField
              id="search"
              placeholder="Buscar ..."
              variant="outlined"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <CropFreeIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                disabled: disabled
              }}
              value={search ? search : ''}
              onChange={e => changeSearch(e)}
              onKeyDown={e => handleKeyDown(e)}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.btn}
              startIcon={<AddIcon />}
              onClick={handleOpenModalType}
            >
              Agregar
            </Button>
            <input
              ref={inputfile}
              type="file"
              name="file"
              id={'file'}
              className={classes.inputFile}
              multiple={true}
              onChange={e => handleAddAssets(e, 'file')}
            />
          </Box>
        </PaperHeader>
        <Box component={'div'} width={'100%'} padding={'0 24px 5px 24px'}></Box>
        <TableContainer>
          <Table aria-label="Tabla de contenido">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  <b>ID</b>
                </TableCell>
                <TableCell>
                  <b>Archivo/URL</b>
                </TableCell>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <b>Tipo</b>
                </TableCell>
                <TableCell>
                  <b>Fecha</b>
                </TableCell>
                <TableCell width={'10px'} />
                <TableCell width={'10px'} />
              </TableRow>
            </TableHead>
            <TableBody>{Assets}</TableBody>
          </Table>
        </TableContainer>
        <PaperFooter style={{ justifyContent: 'center' }}></PaperFooter>
      </CustomPaper>
      <ModalTypeAsset
        handleClick={handleClickOpen}
        open={openModalTypeAsset}
        setOpen={setOpenModalTypeAsset}
      />
      <ModalURLAsset
        open={openModalURL}
        setOpen={setOpenModalURL}
        url={url}
        setUrl={setUrl}
        handleAdd={handleAddAssets}
      />
    </Box>
  );
}
