import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useValidador } from '../../utils/validador';
//Components
import {
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import MapWrapper from '../GoogleMap/MapWrapper';
import GoogleMap from '../GoogleMap/GoogleMap';
import Grid from '@material-ui/core/Grid';
import Marker from '../GoogleMap/Marker';
//Icons
//Img

const styles = makeStyles(theme => ({
  mapWrapper: {
    height: '300px'
  }
}));

export default function PropertyAddress(props) {
  const classes = styles();
  let inputAddress = React.useRef();
  let inputFakeAddress = React.useRef();
  const {
    property,
    handleChangeProperty,
    changeProperty,
    disabled,
    handleChangeCheckboxProperty
  } = props;
  //Hooks
  const properties = useSelector(state => state.properties);
  //State
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [center, setCenter] = useState({ lat: -32.967064, lng: -60.64678 });
  const [latFake, setLatFake] = useState(null);
  const [lngFake, setLngFake] = useState(null);
  const [centerFake, setCenterFake] = useState({
    lat: -32.967064,
    lng: -60.64678
  });
  const [zoom, setZoom] = useState(15);

  //Hooks
  let reglas = {};
  reglas = {
    address: ['required'],
    locality: ['required'],
    state: ['required'],
    country: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    const google = window.google;
    const options = {
      // bounds: defaultBounds,
      // componentRestrictions: { country: "us" },
      fields: ['address_components', 'geometry', 'icon', 'name']
      // strictBounds: false,
      // types: ["establishment"],
    };
    //Address
    var input = inputAddress.current;
    if (google && google.maps && input) {
      let auto = new google.maps.places.Autocomplete(input, options);
      google.maps.event.addListener(auto, 'place_changed', function () {
        var place = auto.getPlace();
        console.log(place);
        let cambio = {};
        for (var i = 0; i < place.address_components.length; i++) {
          for (var j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] == 'postal_code') {
              console.log(place.address_components[i].long_name);
            }
            if (place.address_components[i].types[j] == 'street_number') {
              console.log(place.address_components[i].long_name);
              cambio.number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'route') {
              console.log(place.address_components[i].long_name);
              cambio.street = place.address_components[i].long_name;
            }
            if (
              place.address_components[i].types[j] ==
              'administrative_area_level_1'
            ) {
              console.log('provicnia', place.address_components[i].long_name);
              cambio.state = place.address_components[i].long_name;
            }
            if (
              place.address_components[i].types[j] == 'locality' ||
              place.address_components[i].types[j] == 'sublocality'
            ) {
              console.log(place.address_components[i].long_name);
              cambio.city = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'country') {
              console.log(place.address_components[i].long_name);
              cambio.country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'neighborhood') {
              console.log(place.address_components[i].long_name);
              cambio.neighborhood = place.address_components[i].long_name;
            }
          }
        }
        cambio.address =
          (cambio.street ? cambio.street : '') +
          (cambio.number ? ' ' + cambio.number : '');
        if (cambio.address === '') cambio.address = place.name;
        let lng = place.geometry.location.lng();
        let lat = place.geometry.location.lat();
        cambio.lat = lat;
        cambio.lng = lng;
        changeProperty(cambio);
        setLng(lng);
        setLat(lat);
        setCenter({ lng: lng, lat: lat });
        setZoom(15);
      });
    }

    //FakeAddress
    var inputFake = inputFakeAddress.current;
    if (google && google.maps && inputFake) {
      let autoFake = new google.maps.places.Autocomplete(inputFake, options);
      google.maps.event.addListener(autoFake, 'place_changed', function () {
        var place = autoFake.getPlace();
        console.log(place);
        let cambio = {};
        let lng = place.geometry.location.lng();
        let lat = place.geometry.location.lat();
        cambio.fake_lat = lat;
        cambio.fake_lng = lng;
        cambio.fake_address = place.name;
        changeProperty(cambio);
        setLngFake(lng);
        setLatFake(lat);
        setCenterFake({ lng: lng, lat: lat });
        setZoom(15);
      });
    }

    //Set location on first render
    if (property && property.id) {
      if (property.lat && property.lng) {
        setLng(property.lng);
        setLat(property.lat);
        setCenter({ lng: property.lng, lat: property.lat });
        setZoom(15);
      } else onBlur();

      if (property.fake_lat && property.fake_lng) {
        setLngFake(property.fake_lng);
        setLatFake(property.fake_lat);
        setCenterFake({ lng: property.fake_lng, lat: property.fake_lat });
        setZoom(15);
      } else onBlurFake();
    }
  }, [window.google, property.id]);

  const onBlur = () => {
    const google = window.google;
    if (google && google.maps) {
      var geocoder = new google.maps.Geocoder();
      console.log('onblur');
      console.log(
        `${property.address} ${property.city} ${property.state} ${property.country}`
      );
      if (property.address)
        geocoder.geocode(
          {
            address: `${property.address} ${property.city} ${property.state} ${property.country}`
          },
          function (results, status) {
            if (status === 'ZERO_RESULTS') {
              setZoom(1);
              setLat(null);
              setLng(null);
            }
            if (status !== 'OK') {
              console.log('Geocoder failed due to: ' + status);
              return;
            }
            console.log(results);
            setLat(results[0].geometry.location.lat());
            setLng(results[0].geometry.location.lng());
            setCenter({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
            setZoom(15);
          }
        );
    }
  };

  const onBlurFake = () => {
    const google = window.google;
    if (google && google.maps) {
      var geocoder = new google.maps.Geocoder();
      console.log(
        `${property.fake_address} ${property.city} ${property.state} ${property.country}`
      );
      if (property.fake_address)
        geocoder.geocode(
          {
            address: `${property.fake_address} ${property.city} ${property.state} ${property.country}`
          },
          function (results, status) {
            if (status === 'ZERO_RESULTS') {
              setZoom(1);
              console.log('zoom');
              setLatFake(null);
              setLngFake(null);
            }
            if (status !== 'OK') {
              console.log('Geocoder failed due to: ' + status);
              return;
            }
            console.log(results);
            setLatFake(results[0].geometry.location.lat());
            setLngFake(results[0].geometry.location.lng());
            setCenterFake({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
            setZoom(15);
          }
        );
    }
  };

  const handleDrag = e => {
    let lng = e.latLng.lng();
    let lat = e.latLng.lat();
    let cambio = {};
    cambio.lat = lat;
    cambio.lng = lng;
    setLng(lng);
    setLat(lat);
    changeProperty(cambio);
  };

  const handleDragFake = e => {
    let lng = e.latLng.lng();
    let lat = e.latLng.lat();
    let cambio = {};
    cambio.fake_lat = lat;
    cambio.fake_lng = lng;
    setLngFake(lng);
    setLatFake(lat);
    changeProperty(cambio);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={8} sm={8} xs={8}>
          <TextField
            inputRef={inputAddress}
            id="address"
            name={`addr-${Date()}`}
            placeholder="Calle y número"
            variant="outlined"
            label={'Calle y número'}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              autoComplete: 'offsdfsdddf'
            }}
            disabled={disabled}
            fullWidth
            value={property && property.address ? property.address : ''}
            onChange={handleChangeProperty}
            onKeyPress={e => {
              e.key === 'Enter' && e.preventDefault();
            }}
            onBlur={validar}
            error={!validador.isValidById('address')}
            helperText={validador.getHelperTextById('address')}
          />
        </Grid>
        <Grid item md={2} sm={2} xs={3}>
          <TextField
            id="floor"
            placeholder="Piso"
            variant="outlined"
            label={'Piso'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={property && property.floor ? property.floor : ''}
            onChange={handleChangeProperty}
            onBlur={validar}
            error={!validador.isValidById('floor')}
            helperText={validador.getHelperTextById('floor')}
          />
        </Grid>
        <Grid item md={2} sm={2} xs={2}>
          <TextField
            id="apartment"
            placeholder="Depto"
            variant="outlined"
            label={'Depto'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            disabled={disabled}
            value={property && property.apartment ? property.apartment : ''}
            onChange={handleChangeProperty}
            onBlur={validar}
            error={!validador.isValidById('apartment')}
            helperText={validador.getHelperTextById('apartment')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            id="country"
            placeholder="País"
            variant="outlined"
            name={`country-${Date()}`}
            label={'País'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={property && property.country ? property.country : ''}
            onChange={handleChangeProperty}
            onBlur={validar}
            error={!validador.isValidById('country')}
            helperText={validador.getHelperTextById('country')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            id="state"
            placeholder="Provincia"
            variant="outlined"
            label={'Provincia'}
            name={`state-${Date()}`}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={property && property.state ? property.state : ''}
            onChange={handleChangeProperty}
            onBlur={validar}
            error={!validador.isValidById('state')}
            helperText={validador.getHelperTextById('state')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            id="city"
            placeholder="Localidad"
            variant="outlined"
            label={'Localidad'}
            name={`city-${Date()}`}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={property && property.city ? property.city : ''}
            onChange={handleChangeProperty}
            onBlur={e => {
              validar(e);
              onBlur();
            }}
            error={!validador.isValidById('city')}
            helperText={validador.getHelperTextById('city')}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={6}>
          <TextField
            id="neighborhood"
            placeholder="Barrio"
            variant="outlined"
            label={'Barrio'}
            name={`neigh-${Date()}`}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={
              property && property.neighborhood ? property.neighborhood : ''
            }
            onChange={handleChangeProperty}
            onBlur={validar}
            error={!validador.isValidById('neighborhood')}
            helperText={validador.getHelperTextById('neighborhood')}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.mapWrapper}>
            <MapWrapper>
              <GoogleMap center={center} zoom={zoom} controls={false}>
                {lat && lng && (
                  <Marker
                    draggable={true}
                    position={{ lat: lat, lng: lng }}
                    onDragEnd={handleDrag}
                  />
                )}
              </GoogleMap>
            </MapWrapper>
          </div>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <br />
          <Typography variant={'overline'} component={'span'}>
            ¿Cómo ubicaremos tu propiedad en el aviso?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={property && !!property.isFakeAddress}
                onChange={handleChangeCheckboxProperty}
                disabled={disabled}
                name="isFakeAddress"
                id="isFakeAddress"
                inputProps={{ 'aria-label': 'isFakeAddress' }}
              />
            }
            label="No mostrar la dirección exacta"
          />

          <TextField
            inputRef={inputFakeAddress}
            id="fake_address"
            name={`fakeaddr-${Date()}`}
            placeholder="Dirección ficticia"
            variant="outlined"
            label={'Dirección ficticia'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            style={{ display: property.isFakeAddress ? 'inherit' : 'none' }}
            margin={'normal'}
            disabled={disabled}
            fullWidth
            value={
              property && property.fake_address ? property.fake_address : ''
            }
            onChange={handleChangeProperty}
            onBlur={e => {
              validar(e);
              onBlurFake();
            }}
            error={!validador.isValidById('fake_address')}
            helperText={validador.getHelperTextById('fake_address')}
          />
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <div
            className={classes.mapWrapper}
            style={{ display: property.isFakeAddress ? 'inherit' : 'none' }}
          >
            <MapWrapper>
              <GoogleMap center={centerFake} zoom={zoom} controls={false}>
                {latFake && lngFake && (
                  <Marker
                    draggable={true}
                    position={{ lat: latFake, lng: lngFake }}
                    onDragEnd={handleDragFake}
                  />
                )}
                {latFake && lngFake && window.google && (
                  <Marker
                    icon={{
                      path: window.google.maps.SymbolPath.CIRCLE,
                      fillColor: 'red',
                      fillOpacity: 0.2,
                      scale: 20,
                      strokeColor: 'transparent',
                      strokeWeight: 0.5
                    }}
                    position={{ lat: latFake, lng: lngFake }}
                  />
                )}
              </GoogleMap>
            </MapWrapper>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

PropertyAddress.propTypes = {
  property: PropTypes.object
};
