import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
//Components
import {
  Box,
  Breadcrumbs,
  Chip,
  Container,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import BtnSave from '../../library/Buttons/BtnSave';
import BtnCancel from '../../library/Buttons/BtnCancel';
import Grid from '@material-ui/core/Grid';
import PaperBody from '../../library/CustomPaper/PaperBody';
//Img
import DoneIcon from '@material-ui/icons/Done';
//Hooks
import { useValidador } from '../../utils/validador';
import { useDispatch, useSelector } from 'react-redux';
//Actions
import {
  createUser,
  fetchUser,
  saveCreateUser,
  saveUpdateUser,
  updateUser
} from '../../actions/UserActions';
//reset
import userReset from '../../reset/userReset';
import { useHistory } from 'react-router';
import FacePhoto from './Photo/FacePhoto';
import { fetchRolesIfNeeded } from '../../actions/RoleActions';
import {
  createTenantUserAccess,
  updateTenantUserAccess
} from '../../actions/TenantUserAccessActions';
import CellphoneInput from './CellphoneInput';
import { isValidPhoneNumber } from 'react-phone-number-input';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      flexWrap: 'nowrap'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export default function CRUDUser(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { idUser, idSection } = useParams();
  const mounted = useRef();
  const history = useHistory();
  const msjErrorCellphone = useRef();

  //Store
  const users = useSelector(state => state.users);
  const auth = useSelector(state => state.auth);
  const user = idUser ? users.update.activo : users.create.nuevo;
  const roles = useSelector(state => state.roles);
  const tenantUserAccesses = useSelector(state => state.tenantUserAccesses);
  let idTenantUserAccess =
    user?.tenant_user_access?.length > 0 ? user.tenant_user_access[0] : null;
  let tenantUserAccess = idTenantUserAccess
    ? tenantUserAccesses.byId.tenantUserAccesses[idTenantUserAccess]
    : {};
  if (tenantUserAccesses.update.activos.indexOf(idTenantUserAccess) !== -1)
    tenantUserAccess = tenantUserAccesses.update.activo[idTenantUserAccess];
  if (tenantUserAccesses.create.nuevos.indexOf(idTenantUserAccess) !== -1)
    tenantUserAccess = tenantUserAccesses.create.nuevo[idTenantUserAccess];

  //State
  const [files, setFiles] = useState([]);

  const guardandoLoading = idUser
    ? users.update.isUpdating
    : users.create.isCreating;
  const disabled =
    (idUser ? users.update.isUpdating : users.create.isCreating) ||
    users.byId.isFetching;
  const error = idUser ? users.update.error : users.create.error;

  //Hooks
  let reglas = {};
  reglas = {
    name: ['required'],
    lastname: ['required'],
    email: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    if (idUser) dispatch(fetchUser(idUser));
    dispatch(fetchRolesIfNeeded({}));

    return function cleanup() {
      userReset.resetAll(dispatch);
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!users.create.isCreating && !users.create.error) {
        userReset.resetAll(dispatch);
        window.scrollTo(0, 0);
        history.push('/users/new');
      }
    }
  }, [users.create.isCreating]);

  //User
  const handleChangeUser = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeUser(cambio);
  };

  const changeUser = cambio => {
    if (idUser) dispatch(updateUser(cambio));
    else dispatch(createUser(cambio));
  };

  const handleChangeStatusUser = () => {
    let cambio = {};
    cambio.status = !user.status;
    changeUser(cambio);
  };

  const handleChangeTenantUserAccess = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTenantUserAccess(cambio);
  };

  const changeTenantUserAccess = cambio => {
    let id = idTenantUserAccess;
    if (!id) {
      id = Date.now() + Math.random() + '-';
    }
    cambio.id = id;
    let cambioTenantUserAccess = {};
    cambioTenantUserAccess[id] = cambio;
    if (id.toString().indexOf('-') === -1) {
      if (tenantUserAccesses.update.activos.indexOf(id) === -1) {
        cambio = {
          ...tenantUserAccesses.byId.tenantUserAccesses[id],
          ...cambio
        };
        cambioTenantUserAccess[id] = cambio;
      }
      dispatch(updateTenantUserAccess(cambioTenantUserAccess));
    } else dispatch(createTenantUserAccess(cambioTenantUserAccess));
  };

  //Submit
  const onSubmit = e => {
    e.preventDefault();
    if (!user.cellphone || !isValidPhoneNumber(user.cellphone)) {
      msjErrorCellphone.current.classList.add('visible');
      msjErrorCellphone.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    } else if (validador.isValid()) {
      if (idUser) dispatch(saveUpdateUser(files));
      else dispatch(saveCreateUser(files));
    }
  };

  return (
    <Container maxWidth={'xg'}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/users">
          Usuarios
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {!idUser ? 'Nuevo usuario' : 'Modificar usuario'}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={'overline'} component={'span'}>
                  Datos del usuario
                </Typography>
              </PaperHeader>
              <PaperBody spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <FacePhoto
                    files={files}
                    setFiles={setFiles}
                    changeUser={changeUser}
                    user={user}
                    file={user.path}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <TextField
                    id="name"
                    placeholder="Nombre"
                    variant="outlined"
                    margin={'normal'}
                    label={'Nombre'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'text'
                    }}
                    disabled={disabled}
                    fullWidth
                    value={user && user.name ? user.name : ''}
                    onChange={handleChangeUser}
                    onBlur={validar}
                    error={!validador.isValidById('name')}
                    helperText={validador.getHelperTextById('name')}
                  />
                  <TextField
                    id="lastname"
                    placeholder="Apellido"
                    variant="outlined"
                    label={'Apellido'}
                    margin={'normal'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'text'
                    }}
                    disabled={disabled}
                    fullWidth
                    value={user && user.lastname ? user.lastname : ''}
                    onChange={handleChangeUser}
                    onBlur={validar}
                    error={!validador.isValidById('lastname')}
                    helperText={validador.getHelperTextById('lastname')}
                  />
                  <TextField
                    id="externalId"
                    placeholder="Código externo"
                    variant="outlined"
                    label={'Código externo'}
                    margin={'normal'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'text'
                    }}
                    disabled={disabled}
                    fullWidth
                    value={user && user.externalId ? user.externalId : ''}
                    onChange={handleChangeUser}
                    onBlur={validar}
                    error={!validador.isValidById('externalId')}
                    helperText={validador.getHelperTextById('externalId')}
                  />
                  <Box display={'flex'} justifyContent={'flex-end'}>
                    {user.status === 1 && (
                      <Chip
                        icon={<DoneIcon />}
                        label="Activo"
                        color="primary"
                        variant="outlined"
                      />
                    )}
                    {user.status === 0 && (
                      <Chip
                        icon={<DoneIcon />}
                        label="Inactivo"
                        color="secondary"
                        variant="outlined"
                      />
                    )}
                  </Box>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ visibility: !idUser ? 'hidden' : 'inherit' }}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={user && !!user.status}
                          onChange={() => handleChangeStatusUser()}
                          disabled={disabled}
                          name="estado"
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                      }
                      label="Estado"
                    />
                  </Grid>
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={'overline'} component={'span'}>
                  Contacto
                </Typography>
              </PaperHeader>
              <PaperBody spacing={2}>
                <Grid item md={12} sm={12} xs={12}>
                  <TextField
                    id="email"
                    placeholder="Email"
                    variant="outlined"
                    margin={'normal'}
                    label={'Email'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'text'
                    }}
                    disabled={true}
                    fullWidth
                    value={user && user.email ? user.email : ''}
                    onChange={e => handleChangeUser(e)}
                    onBlur={validar}
                    error={!validador.isValidById('email')}
                    helperText={validador.getHelperTextById('email')}
                  />
                  <CellphoneInput
                    changeUser={changeUser}
                    cellphone={user?.cellphone}
                    msjError={msjErrorCellphone}
                    size={'normal'}
                  />
                  <TextField
                    id={'role_id'}
                    select
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    label={'Rol *'}
                    placeholder={'Seleccione rol'}
                    value={
                      tenantUserAccess && tenantUserAccess.role_id
                        ? tenantUserAccess.role_id
                        : ''
                    }
                    onChange={e => handleChangeTenantUserAccess(e)}
                    InputProps={{
                      disabled: disabled,
                      id: 'role_id',
                      name: 'role_id'
                    }}
                    disabled={disabled}
                    variant="outlined"
                    margin={'normal'}
                    onBlur={validador.validar}
                    error={!validador.isValidById('role_id')}
                    helperText={validador.getHelperTextById('role_id')}
                  >
                    {roles &&
                      roles.allIds.map(idRole => {
                        let role = roles.byId.roles[idRole];
                        if (role)
                          return (
                            <MenuItem value={idRole}>{role.desRole}</MenuItem>
                          );
                      })}
                  </TextField>
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Grid>

          {auth && auth.usuarioLogueado.idUser === user.id && (
            <Grid item md={6} sm={12} xs={12}>
              <CustomPaper>
                <PaperHeader>
                  <Typography variant={'overline'} component={'span'}>
                    Contraseña
                  </Typography>
                </PaperHeader>
                <PaperBody spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="password"
                      label="Cambiar contraseña"
                      placeholder="Cambiar contraseña"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      InputProps={{ type: 'password' }}
                      value={user && user.password ? user.password : ''}
                      onChange={handleChangeUser}
                      onBlur={validar}
                      error={!validador.isValidById('password')}
                      helperText={validador.getHelperTextById('password')}
                    />
                    <TextField
                      id="repetirPassword"
                      label="Repetir contraseña"
                      placeholder="Contraseña"
                      fullWidth
                      margin="normal"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      InputProps={{ type: 'password' }}
                      value={
                        user && user.repetirPassword ? user.repetirPassword : ''
                      }
                      onChange={handleChangeUser}
                      onBlur={validar}
                      error={!validador.isValidById('repetirPassword')}
                      helperText={validador.getHelperTextById(
                        'repetirPassword'
                      )}
                    />
                  </Grid>
                </PaperBody>
              </CustomPaper>
            </Grid>
          )}
        </Grid>

        <ContainerBtn>
          <BtnCancel
            onClick={() =>
              props.location.pathname === '/users/new'
                ? props.history.push('/users')
                : props.history.goBack()
            }
          />
          <BtnSave
            loading={guardandoLoading}
            disabled={disabled}
            error={error}
          />
        </ContainerBtn>
      </form>
    </Container>
  );
}
