import {
  INVALIDATE_STATES,
  ERROR_STATES,
  RECEIVE_STATES,
  REQUEST_STATES,
  RESET_STATES,
  ERROR_STATE,
  RECEIVE_STATE,
  REQUEST_STATE,
  UPDATE_STATE,
  REQUEST_UPDATE_STATE,
  SUCCESS_UPDATE_STATE,
  ERROR_UPDATE_STATE,
  RESET_UPDATE_STATE,
  REQUEST_UPDATE_STATES,
  SUCCESS_UPDATE_STATES,
  ERROR_UPDATE_STATES,
  RESET_UPDATE_STATES,
  CREATE_STATE,
  ERROR_CREATE_STATE,
  REQUEST_CREATE_STATE,
  RESET_CREATE_STATE,
  SUCCESS_CREATE_STATE,
  REQUEST_CREATE_STATES,
  SUCCESS_CREATE_STATES,
  ERROR_CREATE_STATES,
  RESET_CREATE_STATES,
  DELETE_STATE,
  DELETE_CREATE_STATE,
  DELETE_UPDATE_STATE,
  REQUEST_DELETE_STATE,
  SUCCESS_DELETE_STATE,
  ERROR_DELETE_STATE,
  RESET_DELETE_STATE,
  REQUEST_PRINT_STATE,
  SUCCESS_PRINT_STATE,
  ERROR_PRINT_STATE,
  RESET_PRINT_STATE,
  RECEIVE_FILE_STATE,
  REQUEST_PRINT_STATES,
  SUCCESS_PRINT_STATES,
  ERROR_PRINT_STATES,
  RESET_PRINT_STATES,
  RECEIVE_FILE_STATES,
  PRINT_STATE,
  DELETE_PRINT_STATE
} from '../actions/StateActions';

import {
  RECEIVE_COUNTRY,
  RECEIVE_COUNTRIES,
  SUCCESS_DELETE_COUNTRY,
  SUCCESS_CREATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRIES
} from '../actions/CountryActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    states: {},
    files: {}
  };
}

function statesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_STATES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_STATES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_STATES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_STATES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        states: {}
      });
    case RECEIVE_STATES:
      let dato = action.states.entities.states;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        states: merge({}, state.states, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_STATE:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_STATE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_STATE:
      let datoState = action.state.entities.states;
      return Object.assign({}, state, {
        states: merge({}, state.states, datoState),
        isFetching: false
      });
    case RECEIVE_FILE_STATE:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_STATE:
      let datoStateEliminado = action.state.entities.states;
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datoStateEliminado),
          state.states,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_STATE:
      let datoStateCreado = action.state.entities.states;
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datoStateCreado),
          state.states,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_STATES:
      let datosStateCreado = action.states.entities.states;
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datosStateCreado),
          state.states,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_STATE:
      let datoStateActualizado = action.state.entities.states;
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datoStateActualizado),
          state.states,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_STATES:
      let datosStateActualizado = action.states.entities.states;
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datosStateActualizado),
          state.states,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //COUNTRY
    case RECEIVE_COUNTRY:
      let country =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : {};
      return Object.assign({}, state, {
        states: merge({}, state.states, country)
      });
    case RECEIVE_COUNTRIES:
      let countries =
        action.countries.entities && action.countries.entities.states
          ? action.countries.entities.states
          : {};
      return Object.assign({}, state, {
        states: merge({}, state.states, countries)
      });
    case SUCCESS_DELETE_COUNTRY:
      let datocountryEliminado =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : {};
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datocountryEliminado),
          pickBy(state.states, function (state) {
            return state.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_COUNTRY:
      let datocountryCreado =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : {};
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datocountryCreado),
          pickBy(state.states, function (state) {
            return state.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRY:
      let datocountryActualizado =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : {};
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datocountryActualizado),
          pickBy(state.states, function (state) {
            return state.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRIES:
      let datoscountryActualizado =
        action.countries.entities && action.countries.entities.states
          ? action.countries.entities.states
          : {};
      return Object.assign({}, state, {
        states: mergeWith(
          clone(datoscountryActualizado),
          state.states,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        states: {}
      });
    default:
      return state;
  }
}

function allStates(state = [], action) {
  switch (action.type) {
    case RECEIVE_STATES:
      return action.states.result && action.states.result.states
        ? union(action.states.result.states, state)
        : action.states.result
        ? action.states.result
        : state;
    case RECEIVE_STATE:
      return action.state.result ? union([action.state.result], state) : state;

    case SUCCESS_CREATE_STATE:
      let datoStateSCreate = action.state.entities.states;
      let idNuevoSCreate = null;
      if (Object.values(datoStateSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoStateSCreate)[0] &&
          Object.values(datoStateSCreate)[0].id
            ? Object.values(datoStateSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_STATES:
      let statesCreate =
        action.states.entities && action.states.entities.states
          ? action.states.entities.states
          : null;
      return statesCreate
        ? union(
            state,
            Object.values(statesCreate).map(states => {
              return states.id;
            })
          )
        : state;
    case RESET_STATES:
      return [];

    case RECEIVE_COUNTRY:
      let country =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : null;
      return country
        ? union(
            state,
            Object.values(country).map(country => {
              return country.id;
            })
          )
        : state;
    case RECEIVE_COUNTRIES:
      let countries =
        action.countries.entities && action.countries.entities.states
          ? action.countries.entities.states
          : null;
      return countries
        ? union(
            state,
            Object.values(countries).map(countries => {
              return countries.id;
            })
          )
        : state;

    case SUCCESS_DELETE_COUNTRY:
      let countryDelete =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : null;
      return countryDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryDelete).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_CREATE_COUNTRY:
      let countryCreate =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : null;
      return countryCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryCreate).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_COUNTRY:
      let countryUpdate =
        action.country.entities && action.country.entities.states
          ? action.country.entities.states
          : null;
      return countryUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryUpdate).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_COUNTRIES:
      let countriesUpdate =
        action.countries.entities && action.countries.entities.states
          ? action.countries.entities.states
          : null;
      return countriesUpdate
        ? union(
            state,
            Object.values(countriesUpdate).map(countries => {
              return countries.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalStates(state = null, action) {
  switch (action.type) {
    case RECEIVE_STATES:
      return action.states && action.states.result.total
        ? action.states.result.total
        : 0;
    case RESET_STATES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_STATE:
      let dato = action.state.entities.states;
      let state_ =
        dato && Object.keys(dato).length > 0 ? dato[action.state.result] : {};
      return Object.assign({}, state_, {
        isFetching: false,
        didInvalidate: false,
        activo: state_ ? state_ : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_STATE:
      let idsUpdate = [];
      Object.values(action.state).map(stateUpdate => {
        if (stateUpdate && stateUpdate.id) idsUpdate.push(stateUpdate.id);
      });
      return merge({}, state_, {
        activo: action.state,
        activos:
          idsUpdate.length > 0
            ? union(state_.activos, idsUpdate)
            : state_.activos,
        error: ''
      });
    case REQUEST_UPDATE_STATE:
      return Object.assign({}, state_, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_STATE:
      let datoStateActualizado = action.state.entities.states;
      let stateNuevo =
        datoStateActualizado && Object.keys(datoStateActualizado).length > 0
          ? datoStateActualizado[action.state.result]
          : {};
      return Object.assign({}, state_, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: stateNuevo
      });
    case ERROR_UPDATE_STATE:
      return Object.assign({}, state_, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_STATES:
      return Object.assign({}, state_, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_STATES:
      return Object.assign({}, state_, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_STATES:
      return Object.assign({}, state_, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_STATE:
      return Object.assign({}, state_, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //COUNTRY
    //TODO ver si esta bien
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state_, {
        activo: state_.activo,
        activos: state_.activos
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state_, {
        activo: state_.activo,
        activos: state_.activos
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state_, {
        activo: state_.activo,
        activos: state_.activos
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state_, {
        activo: state_.activo,
        activos: state_.activos
      });

    case DELETE_STATE:
      let datoStateDelete = action.state;
      let idsDelete = [];
      Object.values(action.state).map(stateDelete => {
        if (stateDelete && stateDelete.id) idsDelete.push(stateDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state_, {
          activo: omit(clone(state_.activo), Object.keys(datoStateDelete)),
          activos: difference(clone(state_.activos), idsDelete)
        });
      else return state_;
    case DELETE_UPDATE_STATE:
      let datoStateDeleteUpdate = action.state;
      let idsDeleteUpdate = [];
      Object.values(action.state).map(stateDelete => {
        if (stateDelete && stateDelete.id) idsDeleteUpdate.push(stateDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state_, {
          activo: omit(
            clone(state_.activo),
            Object.keys(datoStateDeleteUpdate)
          ),
          activos: difference(clone(state_.activos), idsDeleteUpdate)
        });
      else return state_;
    case SUCCESS_DELETE_STATE:
      let datoStateDeleted = {};
      if (Object.values(action.state.entities.states).length > 0)
        datoStateDeleted = Object.values(action.state.entities.states)[0];
      return Object.assign({}, state_, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoStateDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state_, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_STATE:
      let idsCreate = [];
      Object.values(action.state).map(stateCreate => {
        if (stateCreate && stateCreate.id) idsCreate.push(stateCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.state,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_STATE:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_STATE:
      let datoStateNuevo = action.state.entities.states;
      let stateNuevo =
        datoStateNuevo && Object.keys(datoStateNuevo).length > 0
          ? datoStateNuevo[action.state.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: stateNuevo,
        nuevos: []
      });
    case ERROR_CREATE_STATE:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_STATES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_STATES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_STATES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_STATE:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //COUNTRY
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_STATE:
      let datoStateDelete = action.state;
      let idsDelete = [];
      Object.values(action.state).map(stateDelete => {
        if (stateDelete && stateDelete.id) idsDelete.push(stateDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoStateDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_STATE:
      let datoStateDeleteCreate = action.state;
      let idsDeleteCreate = [];
      Object.values(action.state).map(stateDelete => {
        if (stateDelete && stateDelete.id) idsDeleteCreate.push(stateDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoStateDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_STATE:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.state,
        error: null
      });
    case REQUEST_DELETE_STATE:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_STATE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_STATE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_STATE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //COUNTRY
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_STATE:
      let idsCreate = [];
      Object.values(action.state).map(stateCreate => {
        if (stateCreate && stateCreate.id) idsCreate.push(stateCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.state,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_STATE:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_STATE:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_STATE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_STATES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_STATES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_STATES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_STATE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_STATE:
      let datoStateDeleteCreate = action.state;
      let idsDeleteCreate = [];
      Object.values(action.state).map(stateDelete => {
        if (stateDelete && stateDelete.id) idsDeleteCreate.push(stateDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoStateDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const states = combineReducers({
  byId: statesById,
  allIds: allStates,
  update: update,
  create: create,
  totalStates: totalStates,
  delete: deleter,
  print: print
});

export default states;
