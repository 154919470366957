import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//Actions
import { login, resetLogin } from '../../actions/AuthActions';
import { fetchTenants } from '../../actions/TenantActions';
import authUtil from '../../utils/auth';
import { useHistory, useParams } from 'react-router';
import tenantUtil from '../../utils/tenantUtil';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

export default function Login() {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  let { tenant_id } = useParams();
  const history = useHistory();
  let mounted = useRef();

  //Store
  const auth = useSelector(state => state.auth);
  const tenants = useSelector(state => state.tenants);
  const tenantUserAccesses = useSelector(state => state.tenantUserAccesses);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetLogin());
    };
  }, []);

  useEffect(() => {
    if (authUtil.isLoggedIn()) {
      console.log('aca2', authUtil.isLoggedIn());
      dispatch(fetchTenants({ myTenants: true }));
      if (tenant_id) {
        tenantUtil.setTenant(tenant_id);
        history.push('/');
      }
    }
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        !tenants.byId.isFetching &&
        !tenants.byId.error &&
        tenants.allIds.length >= 1
      ) {
        tenants.allIds.some(id => {
          let tenant = tenants.byId.tenants[id];
          let idTenantUserAccess =
            tenant &&
            tenant.tenant_user_access &&
            tenant.tenant_user_access.length === 1
              ? tenant.tenant_user_access[0]
              : null;
          let tenantUserAccess =
            tenantUserAccesses.byId.tenantUserAccesses[idTenantUserAccess];
          if (tenantUserAccess && tenantUserAccess.status === 1) {
            tenantUtil.setTenant(tenantUserAccess.tenant_id);
            history.push('/');
            return true;
          }
          return false;
        });
      }
    }
  }, [tenants.byId.isFetching]);

  async function submitForm(e) {
    e.preventDefault();
    await dispatch(login(username, password));
    if (authUtil.isLoggedIn()) dispatch(fetchTenants({ myTenants: true }));
  }

  let Errores = '';
  let error = auth.errorMessage;
  if (
    error != null &&
    Object.keys(error).length > 0 &&
    typeof error == 'object'
  ) {
    Errores = Object.keys(error).map(nombre => {
      let mensajeCompleto = '';
      if (error[nombre])
        error[nombre].forEach(mensaje => {
          mensajeCompleto += mensaje;
        });
      return (
        <p key={nombre} style={{ margin: '0px', color: 'red' }}>
          {mensajeCompleto}
        </p>
      );
    });
  } else
    Errores = (
      <p key={0} style={{ margin: '0px', color: 'red' }}>
        {error}
      </p>
    );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Iniciar sesión
        </Typography>
        <br />
        {Errores}
        <form className={classes.form} noValidate onSubmit={e => submitForm(e)}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="DNI/Usuario"
            name="username"
            autoComplete="email"
            autoFocus
            onChange={e => setUsername(e.target.value)}
            value={username}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
            value={password}
          />
          {/*                     <FormControlLabel */}
          {/*                         control={<Checkbox value="remember" color="primary" />} */}
          {/*                         label="Remember me" */}
          {/*                     /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Iniciar sesión
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/olvido" variant="body2">
                Olvide mi contraseña
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                {'No tenés cuenta? Registrate'}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
