import React from 'react';
import SimpleAnalyticCard from '../cards/SimpleAnalyticCard';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';

function SimpleAnalyticsPanel() {
  const analytics = useSelector(state => state.analytics);
  const totalProperties = analytics.byId.analytics['totalProperties'];
  const totalNewProperties = analytics.byId.analytics['totalNewProperties'];
  const totalNewContacts = analytics.byId.analytics['totalNewContacts'];
  const totalSellers = analytics.byId.analytics['totalSellers'];
  const totalClosedOperations =
    analytics.byId.analytics['totalClosedOperations'];
  const totalRetireOperations =
    analytics.byId.analytics['totalRetireOperations'];
  return (
    <Grid container spacing={2} style={{ margin: 0 }}>
      <SimpleAnalyticCard
        title={'Propiedades'}
        icon={'House'}
        value={totalProperties?.metric}
        percentage={
          ((totalProperties?.metric - totalProperties?.prevMetric) * 100) /
          totalProperties?.prevMetric
        }
      />
      <SimpleAnalyticCard
        title={'Nuevas propiedades'}
        icon={'Shelter'}
        value={totalNewProperties?.metric}
        percentage={
          ((totalNewProperties?.metric - totalNewProperties?.prevMetric) *
            100) /
          totalNewProperties?.prevMetric
        }
      />
      <SimpleAnalyticCard
        title={'Consultas'}
        icon={'Question'}
        value={totalNewContacts?.metric}
        percentage={
          ((totalNewContacts?.metric - totalNewContacts?.prevMetric) * 100) /
          totalNewContacts?.prevMetric
        }
      />
      <SimpleAnalyticCard
        title={'Asesores'}
        icon={'Man'}
        value={totalSellers?.metric}
      />
      <SimpleAnalyticCard
        title={'Operaciones cerradas'}
        icon={'Cheers'}
        value={totalClosedOperations?.metric}
        percentage={
          ((totalClosedOperations?.metric - totalClosedOperations?.prevMetric) *
            100) /
          totalClosedOperations?.prevMetric
        }
      />
      <SimpleAnalyticCard
        title={'Operaciones canceladas'}
        icon={'No'}
        value={totalRetireOperations?.metric}
        percentage={
          ((totalRetireOperations?.metric - totalRetireOperations?.prevMetric) *
            100) /
          totalRetireOperations?.prevMetric
        }
      />
    </Grid>
  );
}

SimpleAnalyticsPanel.propTypes = {};

export default SimpleAnalyticsPanel;
