import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropertyItemList from '../PropertyItemList';
import { useDispatch, useSelector } from 'react-redux';
import {
  deletePrintProperty,
  savePrintProperties
} from '../../../actions/PropertyActions';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  ListItemSecondaryAction
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { createTicketActivity } from '../../../actions/TicketActivityActions';
import { saveUpdateTicket } from '../../../actions/TicketActions';

export default function SharePropertiesModal(props) {
  const { setOpen, open, type } = props;
  const dispatch = useDispatch();
  const mounted = useRef();

  const properties = useSelector(state => state.properties);
  const tickets = useSelector(state => state.tickets);
  const ticket = tickets.update.activo;
  const users = useSelector(state => state.users);
  const user =
    ticket && ticket.user_id ? users.byId.users[ticket.user_id] : null;
  const tenants = useSelector(state => state.tenants);
  const tenant = tenants.update.activo;
  const domains = useSelector(state => state.domains);
  const mainDomain = domains.byId.domains[tenant?.domains?.[0]];
  const disabled =
    tickets.update.isUpdating ||
    tickets.create.isCreating ||
    tickets.byId.isFetching ||
    properties.print.isPrinting;

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!properties.print.isPrinting && !properties.print.error) {
        handleClose();
      }
    }
  }, [properties.print.isPrinting]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAccept = value => {
    dispatch(savePrintProperties());
  };

  const handleDelete = id => {
    let cambio = {};
    cambio[id] = { id: id };
    dispatch(deletePrintProperty(cambio));
  };

  const handleWhatsApp = id => {
    if (user?.cellphone && mainDomain?.domain) {
      window.open(
        `https://api.whatsapp.com/send?phone=${user.cellphone.replaceAll(
          '+',
          ''
        )}&text=https://${mainDomain.domain}/property/${id}`
      );
      let id_ = Date.now() + Math.random() + '-';
      let ticketActivity = {
        id: id_,
        text: 'Envio información de propiedad',
        type: 'WhatsApp',
        property_id: id
      };
      let create = {};
      create[id_] = ticketActivity;
      dispatch(createTicketActivity(create));
      dispatch(saveUpdateTicket());
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Compartir propiedades</DialogTitle>
      <DialogContent>
        {type === 'download' && (
          <DialogContentText>
            Las siguientes propiedades serán incluidas en el PDF generado.
          </DialogContentText>
        )}
        {type === 'whatsapp' && (
          <DialogContentText>
            Presione el icono de WhatsApp para compartir el link de la propiedad
            de su web.
          </DialogContentText>
        )}
        <List>
          {properties.print.printers.map(idProperty => {
            let property = idProperty
              ? properties.byId.properties[idProperty]
              : null;
            if (properties.update.activos.indexOf(idProperty) !== -1)
              property = properties.update.activo[idProperty];
            if (properties.print.printers.indexOf(idProperty) !== -1)
              property = properties.print.print[idProperty];
            return (
              <PropertyItemList
                key={idProperty + 'itemlist'}
                property={property}
              >
                {type === 'download' && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDelete(property.id)}
                      disabled={disabled}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
                {type === 'whatsapp' && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleWhatsApp(property.id)}
                      disabled={disabled}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                )}
              </PropertyItemList>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          disabled={disabled}
        >
          Cerrar
        </Button>
        {type === 'download' && (
          <Button
            onClick={handleAccept}
            color="primary"
            autoFocus
            disabled={disabled}
          >
            Generar PDF
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

SharePropertiesModal.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};
