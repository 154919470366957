import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import { Grid, TextField, Typography } from '@material-ui/core';
//Actions
import {
  createDependency,
  updateDependency
} from '../../actions/DependencyActions';
//Hooks
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperBody from '../../library/CustomPaper/PaperBody';
import MapWrapper from '../GoogleMap/MapWrapper';
import GoogleMap from '../GoogleMap/GoogleMap';
import Marker from '../GoogleMap/Marker';
import tenantUtil from '../../utils/tenantUtil';
import UserSelector from '../Property/UserSelector';

const useStyles = makeStyles(theme => ({
  mapWrapper: {
    height: '447px'
  }
}));

export default function CRUDDependency(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { validar, validador, disabled } = props;

  let inputAddress = React.useRef();

  //State
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [center, setCenter] = useState({ lat: -32.967064, lng: -60.64678 });
  const [zoom, setZoom] = useState(15);

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenantUtil.getTenant()
    ? tenants.update.activo
    : tenants.create.nuevo;
  let idDependency = tenant.dependency ? tenant.dependency[0] : null;
  const dependencies = useSelector(state => state.dependencies);
  let dependency = idDependency
    ? dependencies.byId.dependencies[idDependency]
    : {};
  if (dependencies.update.activos.indexOf(idDependency) !== -1)
    dependency = dependencies.update.activo[idDependency];
  if (dependencies.create.nuevos.indexOf(idDependency) !== -1)
    dependency = dependencies.create.nuevo[idDependency];

  useEffect(() => {
    if (tenant?.id && tenant.dependency?.length === 0) {
      let cambio = {
        nameDependency: 'Casa central'
      };
      changeDependency(cambio);
    }
  }, [tenant.id]);

  useEffect(() => {
    const google = window.google;
    const options = {
      fields: ['address_components', 'geometry', 'icon', 'name']
    };
    //Address
    var input = inputAddress.current;
    if (google && google.maps && input) {
      let auto = new google.maps.places.Autocomplete(input, options);
      google.maps.event.addListener(auto, 'place_changed', function () {
        var place = auto.getPlace();
        let cambio = {};
        for (var i = 0; i < place.address_components.length; i++) {
          for (var j = 0; j < place.address_components[i].types.length; j++) {
            if (place.address_components[i].types[j] == 'postal_code') {
              // TODO: What to do with postal_code?
            }
            if (place.address_components[i].types[j] == 'street_number') {
              cambio.number = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'route') {
              cambio.street = place.address_components[i].long_name;
            }
            if (
              place.address_components[i].types[j] ==
              'administrative_area_level_1'
            ) {
              cambio.state = place.address_components[i].long_name;
            }
            if (
              place.address_components[i].types[j] == 'locality' ||
              place.address_components[i].types[j] == 'sublocality'
            ) {
              cambio.city = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'country') {
              cambio.country = place.address_components[i].long_name;
            }
            if (place.address_components[i].types[j] == 'neighborhood') {
              cambio.neighborhood = place.address_components[i].long_name;
            }
          }
        }
        cambio.address =
          (cambio.street ? cambio.street : '') +
          (cambio.number ? ' ' + cambio.number : '');
        if (cambio.address === '') cambio.address = place.name;
        let lng = place.geometry.location.lng();
        let lat = place.geometry.location.lat();
        cambio.lat = lat;
        cambio.lng = lng;
        changeDependency(cambio);
        setLng(lng);
        setLat(lat);
        setCenter({ lng: lng, lat: lat });
        setZoom(15);
      });
    }

    //Set location on first render
    if (dependency && dependency.id) {
      if (dependency.lat && dependency.lng) {
        setLng(dependency.lng);
        setLat(dependency.lat);
        setCenter({ lng: dependency.lng, lat: dependency.lat });
        setZoom(15);
      } else onBlur();
    }
  }, [window.google, dependency.id]);

  const onBlur = () => {
    const google = window.google;
    if (google && google.maps) {
      var geocoder = new google.maps.Geocoder();
      if (dependency.address)
        geocoder.geocode(
          {
            address: `${dependency.address} ${dependency.city} ${dependency.state} ${dependency.country}`
          },
          function (results, status) {
            if (status === 'ZERO_RESULTS') {
              setZoom(1);
              setLat(null);
              setLng(null);
            }
            if (status !== 'OK') {
              console.log('Geocoder failed due to: ' + status);
              return;
            }
            setLat(results[0].geometry.location.lat());
            setLng(results[0].geometry.location.lng());
            setCenter({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            });
            setZoom(15);
          }
        );
    }
  };

  const handleDrag = e => {
    let lng = e.latLng.lng();
    let lat = e.latLng.lat();
    let cambio = {};
    cambio.lat = lat;
    cambio.lng = lng;
    setLng(lng);
    setLat(lat);
    changeDependency(cambio);
  };

  //Dependency
  const handleChangeDependency = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeDependency(cambio);
    validar(e);
  };

  const changeDependency = cambio => {
    let id = idDependency;
    if (!id) {
      id = Date.now() + Math.random() + '-';
    }
    cambio.id = id;
    let cambioDependency = {};
    cambioDependency[id] = cambio;
    if (id.toString().indexOf('-') === -1) {
      if (dependencies.update.activos.indexOf(id) === -1) {
        cambio = { ...dependencies.byId.dependencies[id], ...cambio };
        cambioDependency[id] = cambio;
      }
      dispatch(updateDependency(cambioDependency));
    } else dispatch(createDependency(cambioDependency));
  };

  const onSubmit = e => {
    e.preventDefault();
    console.log('prevent');
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item md={12} sm={12} xs={12} spacing={1}>
        <CustomPaper>
          <PaperBody>
            <Grid
              container
              spacing={1}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant={'overline'} component={'span'}>
                  ¿Cuál es la sucursal principal de tu empresa?
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="nameDependency"
                  label="Nombre"
                  placeholder="Nombre"
                  fullWidth={true}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{}}
                  value={
                    dependency && dependency.nameDependency
                      ? dependency.nameDependency
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('nameDependency')}
                  helperText={validador.getHelperTextById('nameDependency')}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant={'overline'} component={'span'}>
                  ¿Quién es el corredor inmobiliario encargado de esta sucursal?
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <UserSelector
                  handleChangeModel={handleChangeDependency}
                  model={dependency}
                  description={'Corredor inmobiliario'}
                  id={'idAgent'}
                  margin={'normal'}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography
                  variant={'overline'}
                  component={'span'}
                  style={{ marginTop: '15px' }}
                >
                  ¿Dónde está ubicada?
                </Typography>
              </Grid>
              <Grid item md={8} sm={8} xs={8}>
                <form onSubmit={onSubmit}>
                  <TextField
                    inputRef={inputAddress}
                    id="address"
                    name={`addr-${Date()}`}
                    placeholder="Calle y número"
                    variant="outlined"
                    label={'Calle y número'}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{
                      autoComplete: 'offsdfsdddf'
                    }}
                    disabled={disabled}
                    fullWidth
                    value={
                      dependency && dependency.address ? dependency.address : ''
                    }
                    onChange={handleChangeDependency}
                    onKeyPress={e => {
                      e.key === 'Enter' && e.preventDefault();
                    }}
                    onBlur={validar}
                    error={!validador.isValidById('address')}
                    helperText={validador.getHelperTextById('address')}
                  />
                </form>
              </Grid>
              <Grid item md={2} sm={2} xs={3}>
                <TextField
                  id="floor"
                  placeholder="Piso"
                  variant="outlined"
                  label={'Piso'}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  disabled={disabled}
                  fullWidth
                  value={dependency && dependency.floor ? dependency.floor : ''}
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('floor')}
                  helperText={validador.getHelperTextById('floor')}
                />
              </Grid>
              <Grid item md={2} sm={2} xs={2}>
                <TextField
                  id="apartment"
                  placeholder="Depto"
                  variant="outlined"
                  label={'Depto'}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  disabled={disabled}
                  value={
                    dependency && dependency.apartment
                      ? dependency.apartment
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('apartment')}
                  helperText={validador.getHelperTextById('apartment')}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <TextField
                  id="country"
                  placeholder="País"
                  variant="outlined"
                  name={`country-${Date()}`}
                  label={'País'}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  disabled={disabled}
                  fullWidth
                  value={
                    dependency && dependency.country ? dependency.country : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('country')}
                  helperText={validador.getHelperTextById('country')}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <TextField
                  id="state"
                  placeholder="Provincia"
                  variant="outlined"
                  label={'Provincia'}
                  name={`state-${Date()}`}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  disabled={disabled}
                  fullWidth
                  value={dependency && dependency.state ? dependency.state : ''}
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('state')}
                  helperText={validador.getHelperTextById('state')}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <TextField
                  id="city"
                  placeholder="Localidad"
                  variant="outlined"
                  label={'Localidad'}
                  name={`city-${Date()}`}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  disabled={disabled}
                  fullWidth
                  value={dependency && dependency.city ? dependency.city : ''}
                  onChange={handleChangeDependency}
                  onBlur={e => {
                    validar(e);
                    onBlur();
                  }}
                  error={!validador.isValidById('city')}
                  helperText={validador.getHelperTextById('city')}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                <TextField
                  id="neighborhood"
                  placeholder="Barrio"
                  variant="outlined"
                  label={'Barrio'}
                  name={`neigh-${Date()}`}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    // disabled: disabled
                    type: 'text'
                  }}
                  disabled={disabled}
                  fullWidth
                  value={
                    dependency && dependency.neighborhood
                      ? dependency.neighborhood
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('neighborhood')}
                  helperText={validador.getHelperTextById('neighborhood')}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div className={classes.mapWrapper}>
                  <MapWrapper>
                    <GoogleMap center={center} zoom={zoom} controls={false}>
                      {lat && lng && (
                        <Marker
                          draggable={true}
                          position={{ lat: lat, lng: lng }}
                          onDragEnd={handleDrag}
                        />
                      )}
                    </GoogleMap>
                  </MapWrapper>
                </div>
              </Grid>
            </Grid>
          </PaperBody>
        </CustomPaper>
      </Grid>
    </Grid>
  );
}
