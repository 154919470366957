import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//Components
import {
  Breadcrumbs,
  Container,
  InputAdornment,
  TextField,
  Tooltip
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProperties,
  invalidateProperties,
  resetProperties
} from '../../actions/PropertyActions';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import CropFreeIcon from '@material-ui/icons/Search';
import Grow from '../../library/Grow';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import BtnAdd from '../../library/Buttons/BtnAdd';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import PropertyCard from './PropertyCard';
import PaperBody from '../../library/CustomPaper/PaperBody';
import Grid from '@material-ui/core/Grid';
import { fetchOperationTypesIfNeeded } from '../../actions/OperationTypeActions';
import { fetchPropertyTypesIfNeeded } from '../../actions/PropertyTypeActions';
import { fetchCurrenciesIfNeeded } from '../../actions/CurrencyActions';
import { fetchPeriodsIfNeeded } from '../../actions/PeriodActions';
import { fetchAttributesGroupsIfNeeded } from '../../actions/AttributeGroupActions';
import Box from '@material-ui/core/Box';
import PropertiesList from './PropertiesList';
import PropertiesPaginationList from './PropertiesPaginationList';

const useStyles = makeStyles(theme => ({
  footer: {
    // boxShadow: theme.shadows[25],
    backgroundColor: 'transparent',
    width: '100%'
  }
}));

function PropertyCardComponent(props) {
  const classes = useStyles();
  const { property, disabled = false } = props;
  return (
    <CustomPaper>
      <PaperBody spacing={2}>
        <Grid container spacing={3}>
          <PropertyCard property={property} disabled={true} />
          <Grid item md={12} sm={12} xs={12} className={classes.footer}>
            <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
              <Link to={'/properties/' + property.id}>
                <Tooltip title="Editar">
                  <IconButton
                    aria-label="editar"
                    size={'small'}
                    disabled={disabled}
                  >
                    <EditIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </PaperBody>
    </CustomPaper>
  );
}

function PropertiesListPage(props) {
  const dispatch = useDispatch();
  const { title } = props;

  //Store
  const properties = useSelector(state => state.properties);

  //State
  const [search, setSearch] = useState('');

  const params = new URLSearchParams(props.location.search);
  const order = params.get('order') ? params.get('order') : 'id';
  const direction = params.get('direction') ? params.get('direction') : 'asc';
  const page = params.get('page') ? params.get('page') : 1;
  const searchPhase = params.get('sp') ? params.get('sp') : '';

  const disabled = properties.byId.isFetching || properties.delete.isDeleting;

  //Hooks
  const timer = useRef();

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(fetchOperationTypesIfNeeded());
    dispatch(
      fetchPropertyTypesIfNeeded({ with: ['childrenType'], hierarchy: 0 })
    );
    dispatch(fetchCurrenciesIfNeeded());
    dispatch(fetchPeriodsIfNeeded());
    dispatch(
      fetchAttributesGroupsIfNeeded({ with: ['attribute.attributeValue'] })
    );

    if (searchPhase != search) {
      setSearch(searchPhase);
    }

    return function cleanup() {};
  }, []);

  useEffect(() => {
    let filtros = {
      searchPhase: search ? search : '',
      order: order,
      direction: direction,
      page: page,
      with: ['mainAsset', 'propertyAttributeValue', 'propertyOperation']
    };
    if (props.idUser) filtros.idConsultant = props.idUser;

    if (props.location.pathname.indexOf('developments') !== -1)
      filtros.idsPropertyType = [
        23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37
      ];

    dispatch(resetProperties());
    dispatch(invalidateProperties());
    dispatch(fetchProperties(filtros));
    window.scrollTo(0, 0);
  }, [props.location.search, props.location.pathname]);

  const WAIT_INTERVAL = 500;

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    if ((!searchPhase && !search) || searchPhase === '') {
      params.delete('sp');
      props.history.push(props.location.pathname + '?' + params.toString());
    } else {
      params.delete('page');
      params.set('sp', searchPhase ? searchPhase : search);
      props.history.push(props.location.pathname + '?' + params.toString());
    }
  };

  const handleChangePage = (e, page) => {
    let pagina = page;
    let paramsPagina = new URLSearchParams(props.location.search);
    paramsPagina.set('page', pagina ? pagina : '1');
    props.history.push(props.location.pathname + '?' + paramsPagina.toString());
  };

  return (
    <Container maxWidth={'lg'}>
      {title !== false && (
        <>
          <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
            <Link color="inherit" to="/">
              Home
            </Link>
          </Breadcrumbs>
          <Typography component={'h1'} variant={'h1'}>
            {props.location.pathname.indexOf('developments') !== -1
              ? 'Emprendimientos'
              : 'Propiedades'}
          </Typography>
        </>
      )}
      <CustomPaper>
        <PaperHeader>
          <TextField
            id="search"
            placeholder="Buscar ..."
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              )
              // disabled: disabled
            }}
            value={search ? search : ''}
            onChange={e => changeSearch(e)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <Grow />
        </PaperHeader>
      </CustomPaper>
      <PropertiesList
        component={PropertyCardComponent}
        properties={properties}
      />
      <PropertiesPaginationList
        totalProperties={properties?.totalProperties}
        handleChangePage={handleChangePage}
        page={page}
      />
      <ContainerBtn>
        <BtnAdd
          onClick={() => props.history.push('/properties/new')}
          fetching={disabled}
        />
      </ContainerBtn>
    </Container>
  );
}

PropertiesListPage.propTypes = {};

export default PropertiesListPage;
