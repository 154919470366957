import c from '../constants/Constants';

var attributesGroups = {
  getAll(filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');

    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/attributesGroups/?' + query, defaultOptions);
  },
  getOne(idAttributeGroup) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/attributesGroups/' + idAttributeGroup,
      defaultOptions
    );
  },
  getFile(idAttributeGroup, filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        'X-Tenant': localStorage.tenant_id
      }
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/attributesGroups/' + idAttributeGroup + '/file/?' + query,
      defaultOptions
    );
  },
  saveCreate(attributeGroup) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(attributeGroup)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/attributesGroups/', defaultOptions);
  },
  saveUpdate(attributeGroup) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(attributeGroup)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(
      c.BASE_URL + '/attributesGroups/' + attributeGroup.id,
      defaultOptions
    );
  },
  saveUpdateAttributesGroups(activos) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(activos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/attributesGroups/all', defaultOptions);
  },
  saveDelete(attributeGroup) {
    let defaultOptions = {
      url: '',
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/attributesGroups/' + attributeGroup.id,
      defaultOptions
    );
  },
  saveCreateAttributesGroups(nuevos) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(nuevos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/attributesGroups/all', defaultOptions);
  },
  printAttributeGroup(idAttributeGroup) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache'
    };
    return fetch(
      c.BASE_URL + '/attributesGroups/' + idAttributeGroup + '/edit/',
      defaultOptions
    );
  },
  printAttributesGroups(print) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache',
      body: JSON.stringify(print)
    };
    return fetch(
      c.BASE_URL + '/attributesGroups/' + 'print/all',
      defaultOptions
    );
  }
};

export default attributesGroups;
