import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropertyCardSkeleton from './PropertyCardSkeleton';

export default function PropertiesList(props) {
  const { properties, component } = props;
  let Component = component;

  let Properties = [];

  if (properties.byId.isFetching) {
    Properties = [...Array(5).keys()].map(i => {
      return <PropertyCardSkeleton key={'props' + i} />;
    });
  }

  if (!properties.byId.isFetching)
    Properties = properties.allIds.map((idProperty, i) => {
      let property = properties.byId.properties[idProperty];
      return <Component key={'props' + i} property={property} />;
    });

  if (Properties.length === 0)
    Properties.push(
      <Box display={'flex'} justifyContent={'center'}>
        <Typography variant={'caption'}>
          No se encontraron propiedades
        </Typography>
      </Box>
    );

  return <>{Properties}</>;
}
