import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import SearchCard from '../cards/SearchCard';
import { Typography } from '@material-ui/core';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';

const types = [
  { title: 'Tipología', key: 'totalSessions_propertyTypes' },
  { title: 'Tipo de operación', key: 'totalSessions_operationType' },
  { title: 'Cantidad de dormitorios', key: 'totalSessions_beds' },
  { title: 'País', key: 'totalSessions_country_search' },
  { title: 'Provincia/estado', key: 'totalSessions_state' },
  { title: 'Ciudad', key: 'totalSessions_city' },
  { title: 'Barrio', key: 'totalSessions_neighborhood' },
  { title: 'Palabras clave', key: 'totalSessions_sp' }
];

function SearchPanel() {
  return (
    <Grid item xs={12}>
      <CustomPaper style={{ marginTop: 0 }}>
        <PaperBody>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={'button'} component={'h7'}>
              BÚSQUEDAS MÁS FRECUENTES
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            gridColumnGap={'25px'}
            gridRowGap={'25px'}
            flexWrap={'wrap'}
            height={'100%'}
          >
            {types.map(value => {
              return (
                <SearchCard
                  key={value.key}
                  title={value.title}
                  metrics={value.key}
                />
              );
            })}
          </Box>
        </PaperBody>
      </CustomPaper>
    </Grid>
  );
}

SearchPanel.propTypes = {};

export default SearchPanel;
