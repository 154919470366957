import React, { useMemo } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '350px'
  }
}));

function PropertiesCard(props) {
  const classes = useStyles();
  const analytics = useSelector(state => state.analytics);
  const totalSessions_properties = analytics.byId.analytics[props.key1];
  const total = useMemo(() => {
    return totalSessions_properties?.metric.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.value || 0),
      0
    );
  }, totalSessions_properties);

  return (
    <Grid item md={4} sm={12} xs={12}>
      <CustomPaper style={{ height: '100%', marginTop: 0 }}>
        <PaperBody>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={'button'} component={'h7'}>
              {props.title ? props.title : 'PROPIEDADES MÁS VISTAS'}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
            marginTop={'15px'}
            borderBottom={'1px solid rgba(122,120,120,0.2)'}
          >
            <Typography variant={'caption'} component={'h7'}>
              Propiedad
            </Typography>
            <Typography variant={'caption'} component={'h7'}>
              Usuarios
            </Typography>
          </Box>
          <Box
            width={'100%'}
            height={'100px'}
            minHeight={'250px'}
            marginTop={'10px'}
            overflow={'auto'}
          >
            {totalSessions_properties?.metric.map(metric => {
              return (
                <Link
                  to={'/properties/' + metric.id}
                  key={metric.id}
                  target={'_blank'}
                >
                  <Box marginTop={'10px'}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      width={'100%'}
                    >
                      <Typography
                        variant={'caption'}
                        component={'h6'}
                        className={classes.title}
                      >
                        {metric.title}
                      </Typography>
                      <Typography variant={'caption'} component={'h7'}>
                        {metric.value || 0}
                      </Typography>
                    </Box>
                    <Box width={'100%'}>
                      <LinearProgress
                        variant={'determinate'}
                        color={'primary'}
                        value={(metric.value * 100) / total}
                      />
                    </Box>
                  </Box>
                </Link>
              );
            })}
          </Box>
        </PaperBody>
      </CustomPaper>
    </Grid>
  );
}

export default PropertiesCard;
