import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import SignUpUser from './SignUpUser';
import { Grid, Paper } from '@material-ui/core';
import TenantSignUp from './TenantSignUp';
import RegistroSucursal from './RegistroSucursal';
import RegistroListo from './RegistroListo';
import { useDispatch, useSelector } from 'react-redux';
import authUtil from '../../utils/auth';
import userReset from '../../reset/userReset';
import { fetchTenants } from '../../actions/TenantActions';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import { useHistory, useParams } from 'react-router';
import tenantUtil from '../../utils/tenantUtil';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '1280px',
    margin: '0 auto',
    paddingLeft: '24px',
    paddingRight: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    },
    '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
      marginTop: '10px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '6px',
        fontSize: '12px',
        lineHeight: 'initial'
      }
    }
  },
  backButton: {
    marginRight: theme.spacing(1)
  },

  offset: theme.mixins.toolbar
}));

function getSteps() {
  return ['Datos personales', 'Empresa', 'Sucursales', 'Listo'];
}

export default function BriefSignUp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const mounted = useRef();
  const mounted2 = useRef();
  let { tenant_id } = useParams();
  const history = useHistory();

  const tenants = useSelector(state => state.tenants);
  const users = useSelector(state => state.users);
  const tenantUserAccesses = useSelector(state => state.tenantUserAccesses);

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    if (authUtil.isLoggedIn()) dispatch(fetchTenants({ myTenants: true }));
    return function cleanup() {
      userReset.resetAll(dispatch);
      // cuentaReset.resetAll(dispatch);
      localStorage.removeItem('no_volver_a_mostrar_ayuda');
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!users.create.isCreating && !users.create.error) {
        window.scrollTo(0, 0);
      }
    }
  }, [users.create.isCreating]);

  useEffect(() => {
    if (authUtil.isLoggedIn()) {
      dispatch(fetchTenants({ myTenants: true }));
      if (tenant_id) {
        tenantUtil.setTenant(tenant_id);
        history.push('/');
      }
    }
  }, [authUtil.isLoggedIn()]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (
        !tenants.byId.isFetching &&
        !tenants.byId.error &&
        tenants.allIds.length >= 1
      ) {
        tenants.allIds.some(id => {
          let tenant = tenants.byId.tenants[id];
          let idTenantUserAccess =
            tenant &&
            tenant.tenant_user_access &&
            tenant.tenant_user_access.length === 1
              ? tenant.tenant_user_access[0]
              : null;
          let tenantUserAccess =
            tenantUserAccesses.byId.tenantUserAccesses[idTenantUserAccess];
          if (tenantUserAccess && tenantUserAccess.status === 1) {
            tenantUtil.setTenant(tenantUserAccess.tenant_id);
            history.push('/');
            return true;
          }
          return false;
        });
      }
    }
  }, [tenants.byId.isFetching]);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={5} sm={12} xs={12}>
          <Paper style={{ padding: '25px 10px' }}>
            <SignUpUser />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
