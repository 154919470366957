import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import clone from 'lodash/clone';
import c from '../../constants/Constants';
import { createUser, fetchUsers, updateUser } from '../../actions/UserActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import {
  Card,
  CardHeader,
  CircularProgress,
  TextField,
  Tooltip
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import CellphoneInput from './CellphoneInput';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => option.name + option.lastname
});

const useStyles = makeStyles(theme => ({
  selector: {
    border: 'none',
    minWidth: '160px',
    marginRight: '10px',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  avatarUser: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
}));

export default function UserAutocomplete(props) {
  const classes = useStyles();
  const {
    changeTicket,
    user_id,
    margin = 'normal',
    attribute_id,
    attribute_rel,
    description,
    add_option = false,
    validar,
    validador,
    msjErrorCellphone
  } = props;
  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  let user =
    user_id && users.byId.users[user_id] ? users.byId.users[user_id] : {};
  if (users.update.activos.indexOf(user_id) !== -1) {
    user = users.update.activo[user_id];
  }
  if (users.create.nuevos.indexOf(user_id) !== -1) {
    user = users.create.nuevo[user_id];
  }
  const [desUser, setUser] = useState('');
  const [editable, setEditable] = useState(false);
  const disabled = props.disabled || !editable;

  useEffect(() => {
    if (user_id && !desUser && user?.name) {
      setUser(user.name + ' ' + user.lastname);
    }
  }, []);

  const handleSelectUser = (e, value, reason) => {
    let idUserSelect = null;
    let solicitanteSelect = '';
    if (value && value.id) {
      idUserSelect = value.id;
      solicitanteSelect = value.name + ' ' + value.lastname;
      if (users.update.activos.indexOf(idUserSelect) === -1) {
        let ubicacionUpdate = clone(users.byId.users[idUserSelect]);
        let cambio = {};
        cambio[idUserSelect] = ubicacionUpdate;
        dispatch(updateUser(cambio));
      }
    } else if (value && value.name && value.name.indexOf('Agregar') !== -1) {
      idUserSelect = -1;
      solicitanteSelect = 'Nuevo interesado';
    } else if (reason === 'clear') {
      idUserSelect = 0;
      solicitanteSelect = '';
    }
    let cambio = {};
    cambio[attribute_id] = idUserSelect;
    cambio[attribute_rel] = idUserSelect;
    changeTicket(cambio);
    setUser(solicitanteSelect);
    setEditable(true);
  };

  //User
  const timer = useRef();

  function handleChangeUserInput(e, value, reason) {
    if (value && reason === 'input' && value.length > 2) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => triggerChangeUser(value), 300);
    }
    setUser(value);
  }

  const triggerChangeUser = searchPhase => {
    // dispatch(resetUsers());
    // dispatch(invalidateUsers());
    dispatch(fetchUsers({ searchPhase: searchPhase }));
  };

  const handleChangeUser = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeUser(cambio);
  };

  const changeUser = cambio => {
    cambio.id = user_id;
    if (users.update.activos.indexOf(user_id) === -1)
      cambio = { ...user, ...cambio };
    let cambioUsuario = {};
    cambioUsuario[user_id] = cambio;
    if (user_id !== -1) dispatch(updateUser(cambioUsuario));
    else dispatch(createUser(cambioUsuario));
  };

  return (
    <>
      <Autocomplete
        value={user?.name ? user : null}
        size="small"
        onChange={(event, newValue, reason) => {
          handleSelectUser(null, newValue, reason);
        }}
        filterOptions={(options, params) => {
          if (params.inputValue !== '') {
            const filtered = filterOptions(options, params);
            // Suggest the creation of a new value

            if (params && params.inputValue !== '' && add_option) {
              filtered.push({
                inputValue: params.inputValue,
                name: `Agregar nuevo interesado "${params.inputValue}"`,
                lastname: '',
                email: ''
              });
            }

            return filtered;
          } else return [];
        }}
        noOptionsText={'Escriba para buscar'}
        loadingText={'Buscando...'}
        selectOnFocus
        // clearOnBlur
        handleHomeEndKeys
        id={attribute_id}
        options={users.allIds.map(option => {
          let user = users.byId.users[option];
          return user ? user : '';
        })}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.name + ' ' + option.lastname;
        }}
        // renderOption={(option) => option.nombre + ' ' + option.apellido + ' ' + option.email}
        disabled={false}
        renderOption={option => {
          return (
            <Card variant="outlined" style={{ width: '100%' }}>
              <CardHeader
                avatar={
                  <Avatar
                    aria-label="recipe"
                    // className={classes.avatar}
                    src={option?.path ? `${c.API_HOST}${option.path}` : ''}
                  >
                    {option.lastname ? option.lastname.charAt(0) : <AddIcon />}
                  </Avatar>
                }
                title={option.name + ' ' + option.lastname}
                subheader={
                  (option.email ? option.email : '') +
                  (option.dni ? ' - DNI: ' + option.dni : '')
                }
              />
            </Card>
          );
        }}
        fullWidth={false}
        style={{ width: '100%' }}
        // freeSolo
        loading={users.byId.isFetching}
        onInputChange={handleChangeUserInput}
        inputValue={desUser ? desUser : ''}
        renderInput={params => (
          <TextField
            {...params}
            label={description}
            // variant="outlined"
            margin={margin}
            className={classes.selector}
            onBlur={validar ? validar : null}
            error={validador ? !validador.isValidById(attribute_id) : null}
            helperText={
              validador ? validador.getHelperTextById(attribute_id) : null
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {users.byId.isFetching ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  <Tooltip title="Editar">
                    <IconButton
                      aria-label="editar"
                      size={'small'}
                      onClick={() => setEditable(editable => !editable)}
                      className={
                        'MuiAutocomplete-clearIndicator MuiAutocomplete-clearIndicatorDirty'
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {params.InputProps.endAdornment}
                </>
              ),
              startAdornment: (
                <Avatar
                  className={classes.avatarUser}
                  src={user?.path ? `${c.API_HOST}${user.path}` : ''}
                />
              ),
              autoComplete: 'off'
            }}
          />
        )}
      />
      {!!user_id && !!add_option && (
        <>
          <TextField
            size={'small'}
            id="name"
            placeholder="Nombre"
            variant="outlined"
            margin={'normal'}
            label={'Nombre *'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disabled: disabled,
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={user && user.name ? user.name : ''}
            onChange={handleChangeUser}
            onBlur={validar ? validar : null}
            error={validador ? !validador.isValidById('name') : null}
            helperText={validador ? validador.getHelperTextById('name') : null}
          />
          <TextField
            size={'small'}
            id="lastname"
            placeholder="Apellido"
            variant="outlined"
            label={'Apellido *'}
            margin={'normal'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disabled: disabled,
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={user && user.lastname ? user.lastname : ''}
            onChange={handleChangeUser}
            onBlur={validar ? validar : null}
            error={validador ? !validador.isValidById('lastname') : null}
            helperText={
              validador ? validador.getHelperTextById('lastname') : null
            }
          />
          <TextField
            size={'small'}
            id="email"
            placeholder="Email"
            variant="outlined"
            margin={'normal'}
            label={'Email *'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disabled: disabled,
              type: 'text'
            }}
            disabled={disabled}
            fullWidth
            value={user && user.email ? user.email : ''}
            onChange={e => handleChangeUser(e)}
            onBlur={validar ? validar : null}
            error={validador ? !validador.isValidById('email') : null}
            helperText={validador ? validador.getHelperTextById('email') : null}
          />
          <CellphoneInput
            changeUser={changeUser}
            cellphone={user?.cellphone}
            msjError={msjErrorCellphone}
            size={'small'}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
}
