import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import {
  Avatar,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';
//Icons
import PublishIcon from '@material-ui/icons/Publish';
//Actions
//constants
//Hooks
import { useValidador } from '../../utils/validador';
import Button from '@material-ui/core/Button';
import {
  createTenant,
  fetchTenant,
  resetUpdateTenant
} from '../../actions/TenantActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createUser, saveCreateUsers } from '../../actions/UserActions';
import {
  createTenantUserAccess,
  saveCreateTenantUserAccess,
  updateTenantUserAccess
} from '../../actions/TenantUserAccessActions';
//Reset

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    },
    '& input': {
      width: '500px'
    }
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  tarjeta: {
    padding: '10px 20px 20px 20px',
    marginBottom: '17px'
  },
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  },
  colorPicker: {
    '& button': {
      borderRadius: '100%',
      width: '40px',
      height: '40px',
      margin: '0px',
      marginRight: '10px'
    },
    '& div': {
      borderRadius: '100%',
      height: '40px',
      width: '50px'
    }
  },
  instructions: {
    marginTop: theme.spacing(6)
  }
}));

export default function TenantSignUp(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const msjError = useRef();
  const msjImagen = useRef();
  const mounted = useRef();
  let file = React.createRef();
  const {
    handleNext,
    handleBack,
    setFiles,
    cuentaYaExiste,
    setTenantYaExiste
  } = props;

  //State
  const [previews, setPreviews] = useState(null);

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenants.create.nuevo;

  //Hooks
  let reglas = {};
  reglas = {
    name: ['required|notDisabled'],
    codTenant: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!tenant.primaryColor) {
      let cambio = {
        primaryColor: '#05ADEF',
        secondaryColor: '#00E2EC'
      };
      changeTenant(cambio);
    }
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!tenants.byId.isFetching && !tenants.byId.error) {
        if (tenants.update.activo && tenants.update.activo.id) {
          let cambio = {
            tenant_id: tenants.update.activo.id,
            user_id: tenant.user[0]
          };
          changeTenantUserAccess(cambio);
          dispatch(saveCreateUsers());
        } else {
          if (msjError.current) msjError.current.style.display = 'inline';
        }
      }
    }
  }, [tenants.byId.isFetching]);

  const changeTenantUserAccess = cambio => {
    let id = cambio.id;
    if (!id) {
      id = Date.now() + Math.random() + '-';
    }
    cambio.id = id;
    let cambioTenantUserAccess = {};
    cambioTenantUserAccess[id] = cambio;
    if (id.toString().indexOf('-') === -1)
      dispatch(updateTenantUserAccess(cambioTenantUserAccess));
    else dispatch(createTenantUserAccess(cambioTenantUserAccess));
  };

  //Persona
  const handleChangeTenant = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTenant(cambio);
    validar(e);
  };

  const handleChangeAliasTenant = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    cambio['codTenant'] = e.target.value.replaceAll(' ', '').toUpperCase();
    changeTenant(cambio);
    validar(e);
  };

  const handleChangeTenantColorPrincipal = e => {
    let cambio = {};
    cambio['primaryColor'] = '#' + e.hex;
    changeTenant(cambio);
  };

  const handleChangeTenantColorSecundario = e => {
    let cambio = {};
    cambio['secondaryColor'] = '#' + e.hex;
    changeTenant(cambio);
  };

  const changeTenant = cambio => {
    dispatch(createTenant(cambio));
  };

  const onClickBotonPrimario = () => {
    let boton = document.querySelectorAll('[data-testid="colorpicker-button"]');
    if (boton && boton.length == 2) boton[0].click();
  };

  const onClickBotonSecundario = () => {
    let boton = document.querySelectorAll('[data-testid="colorpicker-button"]');
    if (boton && boton.length == 2) boton[1].click();
  };

  const handleChangeTenantYaExiste = () => {
    setTenantYaExiste(prev => !prev);
    dispatch(resetUpdateTenant());
  };

  const verImagen = e => {
    e.preventDefault();
    //Preview
    for (let i = 0; i < file.current.files.length; i++) {
      let filename = file.current.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
      Object.defineProperty(file.current.files[i], 'name', {
        writable: true,
        value: filename
      });
      setFiles(file.current.files[i]);
      setPreviews('loading');
    }

    //Cargar foto
    for (let i = 0; i < file.current.files.length; i++) {
      let fileSubido = file.current.files[i];
      let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
      changeTenant({ originalFilename: filename });
      let reader = new FileReader();
      reader.onloadend = function () {
        setPreviews(reader.result);
      };
      if (fileSubido) {
        reader.readAsDataURL(fileSubido);
      }
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    dispatch(resetUpdateTenant());
    if (msjError.current) msjError.current.style.display = 'none';
    if (msjImagen.current) msjImagen.current.style.display = 'none';
    if (validador.isValid()) {
      if (
        (!tenant.originalFilename ||
          !tenant.primaryColor ||
          !tenant.secondaryColor) &&
        msjImagen.current
      )
        msjImagen.current.style.display = 'inline';
      else if (!cuentaYaExiste) handleNext();
      else dispatch(fetchTenant(tenant.codTenant));
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <form onSubmit={onSubmit}>
        {!cuentaYaExiste && (
          <div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h1" component="h2" color="primary">
                  ¿Cómo se llama tu inmobiliaria?
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="name"
                  label="Nombre"
                  placeholder="Nombre"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{ disabled: !!cuentaYaExiste }}
                  value={tenant && tenant.name ? tenant.name : ''}
                  onChange={handleChangeAliasTenant}
                  onBlur={validar}
                  error={!validador.isValidById('name')}
                  helperText={validador.getHelperTextById('name')}
                />
              </Grid>
              <Grid
                container
                spacing={1}
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Button color="primary" onClick={handleChangeTenantYaExiste}>
                    Ya existe la cuenta
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="codTenant"
                  label="Código de cuenta"
                  placeholder="Código de cuenta"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{ disabled: !!cuentaYaExiste }}
                  value={tenant && tenant.codTenant ? tenant.codTenant : ''}
                  onChange={handleChangeTenant}
                  onBlur={validar}
                  error={!validador.isValidById('codTenant')}
                  helperText={validador.getHelperTextById('codTenant')}
                />
                <FormHelperText>
                  Será utilizado para que otras personas puedan asociarse a la
                  cuenta
                </FormHelperText>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  component="h2"
                  color="primary"
                  style={{ marginTop: '15px' }}
                >
                  Logo de la empresa
                </Typography>
                <FormHelperText
                  ref={msjImagen}
                  style={{ display: 'none', color: 'red' }}
                >
                  Debe completar la imagen de la empresa.
                </FormHelperText>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div style={{ display: 'flex' }}>
                  {previews === null && (
                    <Avatar style={{ marginRight: '10px' }}>
                      <PublishIcon />
                    </Avatar>
                  )}
                  {previews !== null && previews !== 'loading' && (
                    <Avatar style={{ marginRight: '10px' }} src={previews} />
                  )}
                  {previews === 'loading' && <CircularProgress />}
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => file.current.click()}
                  >
                    Subir logo
                  </Button>
                </div>
                <input
                  type="file"
                  ref={file}
                  style={{ display: 'none' }}
                  accept="image/png, image/jpeg"
                  multiple={false}
                  disabled={props.disabled}
                  className="agregarFoto"
                  onChange={e => verImagen(e)}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div style={{ display: 'flex' }}>
                  <div className={classes.colorPicker}>
                    <ColorPicker
                      hideTextfield
                      id={'primaryColor'}
                      onChange={handleChangeTenantColorPrincipal}
                      value={
                        tenant && tenant.primaryColor ? tenant.primaryColor : ''
                      }
                    />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClickBotonPrimario}
                  >
                    Elegir color primario
                  </Button>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <div style={{ display: 'flex' }}>
                  <div className={classes.colorPicker}>
                    <ColorPicker
                      hideTextfield
                      onChange={handleChangeTenantColorSecundario}
                      value={
                        tenant && tenant.secondaryColor
                          ? tenant.secondaryColor
                          : ''
                      }
                    />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClickBotonSecundario}
                  >
                    Elegir color secundario
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {!!cuentaYaExiste && (
          <div>
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h1" component="h2" color="primary">
                  ¿Cómo se llama tu empresa/institución?
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="codTenant"
                  label="Código de cuenta"
                  placeholder="Código de cuenta"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{}}
                  value={tenant && tenant.codTenant ? tenant.codTenant : ''}
                  onChange={handleChangeTenant}
                  onBlur={validar}
                  error={!validador.isValidById('codTenant')}
                  helperText={validador.getHelperTextById('codTenant')}
                />
                <FormHelperText
                  ref={msjError}
                  style={{ display: 'none', color: 'red' }}
                >
                  La cuenta a la cual se desea asociar no existe. Verifique que
                  el código de cuenta sea correcto.
                </FormHelperText>
              </Grid>
              <Grid
                container
                spacing={1}
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Button color="primary" onClick={handleChangeTenantYaExiste}>
                    No existe la cuenta
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          className={classes.instructions}
        >
          <Grid item md={12} sm={12} xs={12}>
            <Button onClick={handleBack} className={classes.backButton}>
              Volver
            </Button>
            <Button variant="contained" color="primary" type={'submit'}>
              {!cuentaYaExiste ? 'Siguiente' : 'Finalizar'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
