import React, { useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidador } from '../../utils/validador';
import { FormControl, FormGroup, FormLabel, MenuItem } from '@material-ui/core';
import {
  createTicket,
  resetCreateTicket,
  saveCreateTicket
} from '../../actions/TicketActions';
import { useDispatch, useSelector } from 'react-redux';
import UserAutocomplete from '../Users/UserAutocomplete';
import { resetCreateUser } from '../../actions/UserActions';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropertyAutocomplete from '../Property/PropertyAutocomplete';
import {
  createTicketProperty,
  resetCreateTicketProperty
} from '../../actions/TicketPropertyActions';
import Box from '@material-ui/core/Box';
import FilterBar from '../Property/FilterBar/FilterBar';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function CreateTicketModal(props) {
  const { open, setOpen } = props;
  //Hooks
  let reglas = {};
  reglas = {
    origin: ['required'],
    text: ['required'],
    ticketStatus_id: ['required'],
    user_id: ['required'],
    consultant_id: ['required'],
    name: ['sometimes|required'],
    lastname: ['sometimes|required'],
    email: ['sometimes|required'],
    cellphone: ['sometimes|required']
  };
  const msjErrorCellphone = useRef();
  const dispatch = useDispatch();
  const [validador, validar] = useValidador(reglas);
  const tickets = useSelector(state => state.tickets);
  const ticket = tickets.create.nuevo;
  const ticketProperties = useSelector(state => state.ticketProperties);
  const ticketStatuses = useSelector(state => state.ticketStatuses);
  const ticketProperty =
    ticket?.ticket_property?.length > 0
      ? ticketProperties.create.nuevo[ticket.ticket_property[0]]
      : null;
  const users = useSelector(state => state.users);
  const user_id = ticket.user_id;
  let user =
    user_id && users.byId.users[user_id] ? users.byId.users[user_id] : {};
  if (users.update.activos.indexOf(user_id) !== -1) {
    user = users.update.activo[user_id];
  }
  if (users.create.nuevos.indexOf(user_id) !== -1) {
    user = users.create.nuevo[user_id];
  }
  const mounted = useRef();
  const disabled = tickets.create.isCreating || tickets.byId.isFetching;
  const [interest, setInterest] = useState(null);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!tickets.create.isCreating && !tickets.create.error) {
        setOpen(false);
        dispatch(resetCreateTicket());
        dispatch(resetCreateUser());
        window.scrollTo(0, 0);
      }
    }
  }, [tickets.create.isCreating]);

  function handleAccept() {
    if (!user.cellphone || !isValidPhoneNumber(user.cellphone)) {
      msjErrorCellphone.current.classList.add('visible');
      msjErrorCellphone.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    } else if (validador.isValid()) {
      dispatch(saveCreateTicket());
    }
  }

  function handleClose() {
    dispatch(resetCreateTicket());
    setOpen(false);
  }

  const handleChangeTicket = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTicket(cambio);
  };

  const handleChangeQueryTicket = value => {
    let cambio = {};
    cambio.query = value;
    changeTicket(cambio);
  };

  const changeTicket = cambio => {
    dispatch(createTicket(cambio));
  };

  const handleChangeInterest = (e, value) => {
    setInterest(value);
  };

  function changeTicketProperty(ticketProperty_) {
    let id = Date.now() + Math.random() + '-';
    if (ticketProperty) id = ticketProperty.id;
    let cambio = { id: id, ...ticketProperty_ };
    let cambio_ = {};
    cambio_[cambio.id] = cambio;
    dispatch(createTicketProperty(cambio_));
  }

  function clearTicketProperty() {
    dispatch(resetCreateTicketProperty());
    let cambio = { property_operation: [] };
    changeTicket(cambio);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nueva oportunidad</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Crearemos un nuevo ticket para poder hacer el seguimiento
          </DialogContentText>
          <TextField
            disabled={disabled}
            autoFocus
            id="origin"
            select
            label="¿Cómo nos contacto?"
            fullWidth
            value={ticket?.origin ? ticket.origin : ''}
            onChange={handleChangeTicket}
            inputProps={{ id: 'origin', name: 'origin' }}
            onBlur={validar}
            error={!validador.isValidById('origin')}
            helperText={validador.getHelperTextById('origin')}
          >
            <MenuItem key={'WhatsApp'} value={'WhatsApp'}>
              WhatsApp
            </MenuItem>
            <MenuItem key={'Teléfono'} value={'Teléfono'}>
              Teléfono
            </MenuItem>
            <MenuItem key={'Web'} value={'Web'}>
              Web
            </MenuItem>
            <MenuItem key={'Presencial'} value={'Presencial'}>
              Presencial
            </MenuItem>
            <MenuItem key={'Facebook'} value={'Facebook'}>
              Facebook
            </MenuItem>
            <MenuItem key={'Instagram'} value={'Instagram'}>
              Instagram
            </MenuItem>
            <MenuItem key={'Referido'} value={'Referido'}>
              Referido
            </MenuItem>
          </TextField>
          <UserAutocomplete
            user_id={ticket?.user_id}
            changeTicket={changeTicket}
            attribute_rel={'user'}
            attribute_id={'user_id'}
            description={'Persona de contacto'}
            add_option={true}
            disabled={disabled}
            validador={validador}
            validar={validar}
            msjErrorCellphone={msjErrorCellphone}
          />
          <UserAutocomplete
            user_id={ticket?.consultant_id}
            changeTicket={changeTicket}
            attribute_rel={'consultant'}
            attribute_id={'consultant_id'}
            description={'Asesor encargado'}
            disabled={disabled}
            validador={validador}
            validar={validar}
          />
          <TextField
            id="ticketStatus_id"
            disabled={disabled}
            select
            size={'small'}
            label="Estado de la oportunidad"
            fullWidth
            margin={'normal'}
            value={ticket?.ticketStatus_id ? ticket.ticketStatus_id : ''}
            onChange={handleChangeTicket}
            inputProps={{ id: 'ticketStatus_id', name: 'ticketStatus_id' }}
            onBlur={validar}
            error={!validador.isValidById('ticketStatus_id')}
            helperText={validador.getHelperTextById('ticketStatus_id')}
          >
            {ticketStatuses?.allIds?.ticketStatus?.map(id => {
              let ticketStatus = ticketStatuses.byId.ticketStatuses[id];
              return (
                <MenuItem
                  key={'menu' + ticketStatus?.desStatus}
                  value={ticketStatus.id}
                >
                  {ticketStatus?.desStatus}
                </MenuItem>
              );
            })}
          </TextField>
          <TextField
            margin={'normal'}
            disabled={disabled}
            id="text"
            label="Texto del mensaje"
            type="text"
            multiline
            rows={3}
            fullWidth
            value={ticket && ticket.text ? ticket.text : ''}
            onChange={handleChangeTicket}
            onBlur={validar}
            error={!validador.isValidById('text')}
            helperText={validador.getHelperTextById('text')}
          />
          <FormControl
            component="fieldset"
            variant="standard"
            className={'operationType'}
          >
            <FormLabel component="legend" className={'formLabel'}>
              ¿Qué le interesa al nuevo prospecto?
            </FormLabel>
            <FormGroup>
              <ToggleButtonGroup
                margin={'normal'}
                color="primary"
                value={interest}
                exclusive
                onChange={handleChangeInterest}
                variant={'filled'}
                style={{ marginTop: '10px' }}
              >
                <ToggleButton value="property" fullWidth>
                  Una propiedad en particular
                </ToggleButton>
                <ToggleButton value="other" fullWidth>
                  Lista de intereses
                </ToggleButton>
              </ToggleButtonGroup>
            </FormGroup>
          </FormControl>
          {interest === 'property' && (
            <PropertyAutocomplete
              changeModel={changeTicketProperty}
              clearModel={clearTicketProperty}
              property_id={ticketProperty?.property_id}
              attribute_id={'property_id'}
              attribute_rel={'property'}
              description={'Seleccione propiedad de interes'}
              add_option={false}
            />
          )}
          {interest === 'other' && (
            <Box
              display={'flex'}
              justifyContent={'center'}
              marginTop={'10px'}
              gridColumnGap={'10px'}
              gridRowGap={'10px'}
              flexWrap={'wrap'}
            >
              <FilterBar setQueryRouter={handleChangeQueryTicket} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={disabled}>
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={disabled}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
