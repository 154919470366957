import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import { Avatar, Container, Grid, Typography } from '@material-ui/core';
//Icons
//Actions
//constants
//Hooks
//Reset
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.success.main
  },
  [theme.breakpoints.down('sm')]: {
    '& h2': {
      fontSize: '1rem',
      lineHeight: 'initial'
    }
  }
}));

export default function RegistroListo(props) {
  const classes = useStyles();
  const [cont, setCont] = useState(5);
  const mounted = useRef();
  const history = useHistory();

  //Store
  const tenants = useSelector(state => state.tenants);
  let tenant_id =
    tenants.allIds && tenants.allIds.length >= 1 ? tenants.allIds[0] : null;
  let tenant = tenant_id ? tenants.byId.tenants[tenant_id] : {};
  const users = useSelector(state => state.users);
  let idPersona =
    users.allIds && users.allIds.length >= 1 ? users.allIds[0] : null;
  let user = idPersona ? users.byId.users[idPersona] : {};
  if (users.update.activos.indexOf(idPersona) !== -1)
    user = users.update.activo[idPersona];
  if (users.create.nuevos.indexOf(idPersona) !== -1)
    user = users.create.nuevo[idPersona];

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    avanzar();
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else if ((user && user.tenant_id) || (tenant && tenant.id)) {
      let tenant_id = user && user.tenant_id ? user.tenant_id : tenant.id;
      history.push('/' + tenant_id + '/');
    }
  }, [cont === 0]);

  //State
  const avanzar = async () => {
    for (let i = 5; i >= 0; i--) {
      await sleep(1000);
      setCont(i);
    }
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid
        container
        spacing={1}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item lg={6} sm={12} xs={12}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Avatar className={classes.large}>
              <CheckIcon />
            </Avatar>
            <Typography
              variant="h1"
              component="h2"
              color="primary"
              style={{ marginTop: '30px' }}
            >
              Perfecto! Su registro se creo correctamente
            </Typography>
            <Typography
              variant="body"
              component="h2"
              color="primary"
              style={{ marginTop: '30px' }}
            >
              {'Será redirigido en ' + cont}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
