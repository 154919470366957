import c from '../constants/Constants';

var roles = {
  getAll(filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');

    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/roles/?' + query, defaultOptions);
  },
  getOne(idRole) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/roles/' + idRole, defaultOptions);
  },
  getFile(idRole, filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        'X-Tenant': localStorage.tenant_id
      }
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/roles/' + idRole + '/file/?' + query,
      defaultOptions
    );
  },
  saveCreate(role) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(role)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/roles/', defaultOptions);
  },
  saveUpdate(role) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(role)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/roles/' + role.id, defaultOptions);
  },
  saveUpdateRoles(activos) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(activos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/roles/all', defaultOptions);
  },
  saveDelete(role) {
    let defaultOptions = {
      url: '',
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/roles/' + role.id, defaultOptions);
  },
  saveCreateRoles(nuevos) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(nuevos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/roles/all', defaultOptions);
  },
  printRole(idRole) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache'
    };
    return fetch(c.BASE_URL + '/roles/' + idRole + '/edit/', defaultOptions);
  },
  printRoles(print) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache',
      body: JSON.stringify(print)
    };
    return fetch(c.BASE_URL + '/roles/' + 'print/all', defaultOptions);
  }
};

export default roles;
