import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import Button from '@material-ui/core/Button';
import { Container, Grid, TextField, Typography } from '@material-ui/core';
//Actions
import {
  createDependency,
  updateDependency
} from '../../actions/DependencyActions';
//Hooks
import { useValidador } from '../../utils/validador';
import { saveCreateTenant } from '../../actions/TenantActions';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    },
    '& input': {
      width: '500px'
    }
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  tarjeta: {
    padding: '10px 20px 20px 20px',
    marginBottom: '17px'
  },
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  },
  colorPicker: {
    '& button': {
      borderRadius: '100%',
      width: '40px',
      height: '40px',
      margin: '0px',
      marginRight: '10px'
    },
    '& div': {
      borderRadius: '100%',
      height: '40px',
      width: '50px'
    }
  },
  instructions: {
    marginTop: theme.spacing(6)
  }
}));

export default function SignUpDependency(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleBack, files } = props;

  //State

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenants.create.nuevo ? tenants.create.nuevo : {};
  let idDependency =
    tenants.create.nuevo && tenants.create.nuevo.dependency
      ? tenants.create.nuevo.dependency[0]
      : null;
  const dependencies = useSelector(state => state.dependencies);
  let dependency = idDependency
    ? dependencies.byId.dependencies[idDependency]
    : {};
  if (dependencies.update.activos.indexOf(idDependency) !== -1)
    dependency = dependencies.update.activo[idDependency];
  if (dependencies.create.nuevos.indexOf(idDependency) !== -1)
    dependency = dependencies.create.nuevo[idDependency];

  const guardandoLoading = tenants.create.isCreating;
  const error = tenants.create.error;

  //Hooks
  let reglas = {};
  reglas = {
    name: ['required'],
    street: ['required'],
    number: ['required'],
    state: ['required'],
    city: ['required'],
    codPostal: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tenant && !dependency.street) {
      let cambio = {
        street: tenant.street,
        number: tenant.number,
        city: tenant.city,
        state: tenant.state,
        codPostal: tenant.codPostal,
        phoneNumber1: tenant.phoneNumber1,
        name: 'Casa central'
      };
      changeDependency(cambio);
    }
  }, []);

  //Persona
  const handleChangeDependency = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeDependency(cambio);
    validar(e);
  };

  const changeDependency = cambio => {
    let id = idDependency;
    if (!id) {
      id = Date.now() + Math.random() + '-';
    }
    cambio.id = id;
    let cambioDependency = {};
    cambioDependency[id] = cambio;
    if (id.toString().indexOf('-') === -1)
      dispatch(updateDependency(cambioDependency));
    else dispatch(createDependency(cambioDependency));
  };

  const onSubmit = e => {
    e.preventDefault();
    if (validador.isValid()) {
      dispatch(saveCreateTenant([files]));
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="h1" component="h2" color="primary">
              ¿Cuál es la sucursal principal de tu empresa?
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              id="name"
              label="Nombre"
              placeholder="Nombre"
              fullWidth={true}
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{}}
              value={dependency && dependency.name ? dependency.name : ''}
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('name')}
              helperText={validador.getHelperTextById('name')}
              disabled={guardandoLoading}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              component="h2"
              color="primary"
              style={{ marginTop: '15px' }}
            >
              ¿Dónde está ubicada?
            </Typography>
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              id="street"
              label="Calle"
              placeholder="Calle"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                type: 'text'
              }}
              value={dependency && dependency.street ? dependency.street : ''}
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('street')}
              helperText={validador.getHelperTextById('street')}
              disabled={guardandoLoading}
            />
            <TextField
              id="number"
              label="Número"
              placeholder="Número"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                type: 'text'
              }}
              value={dependency && dependency.number ? dependency.number : ''}
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('number')}
              helperText={validador.getHelperTextById('number')}
              disabled={guardandoLoading}
            />
            <TextField
              id="state"
              label="Provincia"
              placeholder="Provincia"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                type: 'text'
              }}
              value={dependency && dependency.state ? dependency.state : ''}
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('state')}
              helperText={validador.getHelperTextById('state')}
              disabled={guardandoLoading}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <TextField
              id="city"
              label="Localidad"
              placeholder="Localidad"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                type: 'text'
              }}
              value={dependency && dependency.city ? dependency.city : ''}
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('city')}
              helperText={validador.getHelperTextById('city')}
              disabled={guardandoLoading}
            />
            <TextField
              id="codPostal"
              label="Código postal"
              placeholder="Código postal"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                type: 'text'
              }}
              value={
                dependency && dependency.codPostal ? dependency.codPostal : ''
              }
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('codPostal')}
              helperText={validador.getHelperTextById('codPostal')}
              disabled={guardandoLoading}
            />
            <TextField
              id="phoneNumber1"
              label="Telefono"
              placeholder="Telefono"
              fullWidth
              margin="dense"
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                type: 'text'
              }}
              value={
                dependency && dependency.phoneNumber1
                  ? dependency.phoneNumber1
                  : ''
              }
              onChange={handleChangeDependency}
              onBlur={validar}
              error={!validador.isValidById('phoneNumber1')}
              helperText={validador.getHelperTextById('phoneNumber1')}
              disabled={guardandoLoading}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          className={classes.instructions}
        >
          <Grid item md={12} sm={12} xs={12}>
            <Button
              disabled={guardandoLoading}
              onClick={handleBack}
              className={classes.backButton}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              color="primary"
              type={'submit'}
              disabled={guardandoLoading}
            >
              Finalizar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
