import c from '../constants/Constants';

var locations = {
  getAll(filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');

    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/locations/?' + query, defaultOptions);
  },
  getOne(idLocation) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/locations/' + idLocation, defaultOptions);
  },
  getFile(idLocation, filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        'X-Tenant': localStorage.tenant_id
      }
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/locations/' + idLocation + '/file/?' + query,
      defaultOptions
    );
  },
  saveCreate(location) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(location)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/locations/', defaultOptions);
  },
  saveUpdate(location) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(location)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/locations/' + location.id, defaultOptions);
  },
  saveUpdateLocations(activos) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(activos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/locations/all', defaultOptions);
  },
  saveDelete(location) {
    let defaultOptions = {
      url: '',
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/locations/' + location.id, defaultOptions);
  },
  saveCreateLocations(nuevos) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(nuevos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/locations/all', defaultOptions);
  },
  printLocation(idLocation) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache'
    };
    return fetch(
      c.BASE_URL + '/locations/' + idLocation + '/edit/',
      defaultOptions
    );
  },
  printLocations(print) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache',
      body: JSON.stringify(print)
    };
    return fetch(c.BASE_URL + '/locations/' + 'print/all', defaultOptions);
  }
};

export default locations;
