import React, { useMemo } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';

function CountriesCard() {
  const analytics = useSelector(state => state.analytics);
  const totalSessions_country =
    analytics.byId.analytics['totalSessions_country'];
  const total = useMemo(() => {
    return totalSessions_country?.metric.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
  }, totalSessions_country);

  return (
    <Grid item md={4} sm={12} xs={12}>
      <CustomPaper style={{ height: '100%', marginTop: 0 }}>
        <PaperBody>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={'button'} component={'h7'}>
              UBICACIÓN GEOGRÁFICA DE LOS USUARIOS
            </Typography>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
            marginTop={'15px'}
            borderBottom={'1px solid rgba(122,120,120,0.2)'}
          >
            <Typography variant={'caption'} component={'h7'}>
              Países
            </Typography>
            <Typography variant={'caption'} component={'h7'}>
              Usuarios
            </Typography>
          </Box>
          <Box
            width={'100%'}
            height={'100px'}
            minHeight={'100%'}
            marginTop={'10px'}
          >
            {totalSessions_country?.metric.map(metric => {
              return (
                <Box marginTop={'10px'}>
                  <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    width={'100%'}
                  >
                    <Typography variant={'caption'} component={'h6'}>
                      {metric.country}
                    </Typography>
                    <Typography variant={'caption'} component={'h7'}>
                      {metric.value}
                    </Typography>
                  </Box>
                  <Box width={'100%'}>
                    <LinearProgress
                      variant={'determinate'}
                      color={'primary'}
                      value={(metric.value * 100) / total}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </PaperBody>
      </CustomPaper>
    </Grid>
  );
}

export default CountriesCard;
